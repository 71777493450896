
export const showFroniusLogo = "show-fronius-logo";

export const showSignatureLine = "show-signature-line";

export const customerName = "customer-name";

export const customerNumber = "customer-number";

export const orderNumber = "order-number";

export const remarks = "remarks";

export const newComponent = "component";

export const company = "company";

export const welder = "welder";

export const measurementUnits = "measurement-units";

export const addFiles = "add-files";

export const clearAllFiles = "clear-all-files";

export const generatePdf = "generate-pdf";

export const fileUpload = "file-upload";

export const cancelButton = "cancel";

export const openPdfModal = "open-pdf-modal";

export const weldsTable = "welds-table";

export const password = "password";

export const username = "username";

export const loginButton = "submitButton";

export const loginLink = "login-link";

export const acceptTos = "accept-tos";

export const tosText = "tos-text";

export const imprintText = "imprint-text";

export const imprintLink = "imprint-link";

export const tosLink = "tos-link";

export const logoAndName = "logo-and-name";

export const cookiePolicyLink = "kekse-politik";

export const dataPolicyLink = "data-privacy";

export const acceptCookiePolicy = "CybotCookiebotDialogBodyButtonAccept";

