import { Record, Union } from "../Client/fable_modules/fable-library.3.7.11/Types.js";
import { record_type, lambda_type, option_type, string_type, float64_type, union_type } from "../Client/fable_modules/fable-library.3.7.11/Reflection.js";
import { WeldingStatus_toString, WeldingMode_toString, WeldingProcess_toString, EasyDocuWeld$reflection } from "./Remoting.fs.js";
import { filter, ofArray, map } from "../Client/fable_modules/fable-library.3.7.11/List.js";
import { equals, int32ToString } from "../Client/fable_modules/fable-library.3.7.11/Util.js";
import { formatFloat, formatMinSec } from "./Format.fs.js";
import { map as map_1, defaultArg } from "../Client/fable_modules/fable-library.3.7.11/Option.js";

export class Alignment extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Left", "Right", "Center"];
    }
}

export function Alignment$reflection() {
    return union_type("WeldingTable.Alignment", [], Alignment, () => [[], [], []]);
}

export class WeldingTableColumn extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SeamNumber", "Date", "Duration", "WireFeedMetric", "WireFeedImperial", "Current", "Voltage", "Power", "Energy", "Process", "Mode", "SynergicLineId", "EasyJob", "MotorCurrent", "Status", "Interval"];
    }
}

export function WeldingTableColumn$reflection() {
    return union_type("WeldingTable.WeldingTableColumn", [], WeldingTableColumn, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class Column extends Record {
    constructor(alignment, weight, column, unit, formatValue) {
        super();
        this.alignment = alignment;
        this.weight = weight;
        this.column = column;
        this.unit = unit;
        this.formatValue = formatValue;
    }
}

export function Column$reflection() {
    return record_type("WeldingTable.Column", [], Column, () => [["alignment", Alignment$reflection()], ["weight", float64_type], ["column", WeldingTableColumn$reflection()], ["unit", option_type(string_type)], ["formatValue", lambda_type(EasyDocuWeld$reflection(), string_type)]]);
}

function columns(formatDateTime, inlineUnits) {
    return map((x) => {
        let matchValue, unit;
        return new Column(x.alignment, x.weight, x.column, x.unit, (matchValue = [inlineUnits, x.unit], matchValue[0] ? ((matchValue[1] != null) ? ((unit = matchValue[1], (weld) => {
            const value_6 = x.formatValue(weld);
            return `${value_6} ${unit}`;
        })) : x.formatValue) : x.formatValue));
    }, ofArray([new Column(new Alignment(2), 0.7, new WeldingTableColumn(0), void 0, (w) => int32ToString(w.SeamNumber)), new Column(new Alignment(2), 1.4, new WeldingTableColumn(1), void 0, (w_1) => formatDateTime(w_1.Date)), new Column(new Alignment(1), 1.2, new WeldingTableColumn(2), void 0, (w_2) => formatMinSec(w_2.Duration)), new Column(new Alignment(1), 0.9, new WeldingTableColumn(3), "m/min", (w_3) => formatFloat(w_3.WireFeedMetric)), new Column(new Alignment(1), 0.9, new WeldingTableColumn(4), "ipm", (w_4) => w_4.WireFeedImperial.toString()), new Column(new Alignment(1), 0.7, new WeldingTableColumn(5), "A", (w_5) => w_5.Current.toString()), new Column(new Alignment(1), 0.8, new WeldingTableColumn(6), "V", (w_6) => formatFloat(w_6.Voltage)), new Column(new Alignment(1), 0.9, new WeldingTableColumn(7), "W", (w_7) => w_7.Power.toString()), new Column(new Alignment(1), 0.9, new WeldingTableColumn(8), "kJ", (w_8) => formatFloat(w_8.Energy)), new Column(new Alignment(2), 1.8, new WeldingTableColumn(9), void 0, (w_9) => WeldingProcess_toString(w_9.Process)), new Column(new Alignment(2), 0.6, new WeldingTableColumn(10), void 0, (w_10) => defaultArg(map_1(WeldingMode_toString, w_10.Mode), "")), new Column(new Alignment(2), 0.7, new WeldingTableColumn(11), void 0, (w_11) => int32ToString(w_11.SynergicLineId)), new Column(new Alignment(1), 0.6, new WeldingTableColumn(12), void 0, (w_12) => defaultArg(map_1(int32ToString, w_12.EasyJob), "")), new Column(new Alignment(2), 0.7, new WeldingTableColumn(13), "A", (w_13) => formatFloat(w_13.MotorCurrent)), new Column(new Alignment(2), 1.5, new WeldingTableColumn(14), void 0, (w_14) => WeldingStatus_toString(w_14.Status)), new Column(new Alignment(2), 0.6, new WeldingTableColumn(15), void 0, (w_15) => int32ToString(defaultArg(w_15.Interval, 0)))]));
}

export function metricColumns(formatDate, inlineUnits) {
    return filter((x) => (!equals(x.column, new WeldingTableColumn(4))), columns(formatDate, inlineUnits));
}

export function imperialColumns(formatDate, inlineUnits) {
    return filter((x) => (!equals(x.column, new WeldingTableColumn(3))), columns(formatDate, inlineUnits));
}

