import { Record } from "./fable_modules/fable-library.3.7.11/Types.js";
import { option_type, tuple_type, lambda_type, unit_type, class_type, bool_type, record_type, list_type, string_type } from "./fable_modules/fable-library.3.7.11/Reflection.js";
import { ofArray, toArray, isEmpty, cons, empty } from "./fable_modules/fable-library.3.7.11/List.js";
import { createElement } from "react";
import * as react from "react";
import { split, substring, printf, toText, isNullOrEmpty, join } from "./fable_modules/fable-library.3.7.11/String.js";
import { createObj } from "./fable_modules/fable-library.3.7.11/Util.js";
import { map, iterate, empty as empty_1, singleton, append, delay as delay_1, toList } from "./fable_modules/fable-library.3.7.11/Seq.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/./Interop.fs.js";
import { useFeliz_React__React_useState_Static_1505, useReact_useRef_1505 } from "./fable_modules/Feliz.1.64.0/React.fs.js";
import { toArray as toArray_1 } from "./fable_modules/fable-library.3.7.11/Option.js";
import { info, icon as icon_1, timeout, message as message_1, Position, position, dismissOnClick, withCloseButton, error } from "./fable_modules/Thoth.Elmish.Toast.3.0.0/Toast.fs.js";
import { fromSeconds } from "./fable_modules/fable-library.3.7.11/TimeSpan.js";
import { MeasurementUnits, Language } from "../Shared/Remoting.fs.js";
import { ofArray as ofArray_1, tryFind } from "./fable_modules/fable-library.3.7.11/Map.js";
import { equalsWith, map as map_1 } from "./fable_modules/fable-library.3.7.11/Array.js";

export class IconOptions extends Record {
    constructor(Title, IconClasses) {
        super();
        this.Title = Title;
        this.IconClasses = IconClasses;
    }
}

export function IconOptions$reflection() {
    return record_type("UIHelpers.IconOptions", [], IconOptions, () => [["Title", string_type], ["IconClasses", list_type(string_type)]]);
}

export function IconOptions_get_Default() {
    return new IconOptions("", empty());
}

export class TextWithSubOptions extends Record {
    constructor(Text$, Sub) {
        super();
        this.Text = Text$;
        this.Sub = Sub;
    }
}

export function TextWithSubOptions$reflection() {
    return record_type("UIHelpers.TextWithSubOptions", [], TextWithSubOptions, () => [["Text", string_type], ["Sub", string_type]]);
}

export function TextWithSubOptions_get_Default() {
    return new TextWithSubOptions("", "");
}

export class ButtonOptions extends Record {
    constructor(Id, Disabled, Text$, CustomClasses, IconClasses, OnClick) {
        super();
        this.Id = Id;
        this.Disabled = Disabled;
        this.Text = Text$;
        this.CustomClasses = CustomClasses;
        this.IconClasses = IconClasses;
        this.OnClick = OnClick;
    }
}

export function ButtonOptions$reflection() {
    return record_type("UIHelpers.ButtonOptions", [], ButtonOptions, () => [["Id", string_type], ["Disabled", bool_type], ["Text", string_type], ["CustomClasses", list_type(string_type)], ["IconClasses", list_type(string_type)], ["OnClick", lambda_type(class_type("Browser.Types.MouseEvent", void 0, MouseEvent), unit_type)]]);
}

export function ButtonOptions_get_Default() {
    return new ButtonOptions("", false, "", empty(), empty(), (_arg1) => {
    });
}

export class DropdownOptions extends Record {
    constructor(Id, DropdownContentHeader, CustomClasses, TriggerElement, CloseDropdownOnClick, DropdownItems) {
        super();
        this.Id = Id;
        this.DropdownContentHeader = DropdownContentHeader;
        this.CustomClasses = CustomClasses;
        this.TriggerElement = TriggerElement;
        this.CloseDropdownOnClick = CloseDropdownOnClick;
        this.DropdownItems = DropdownItems;
    }
}

export function DropdownOptions$reflection() {
    return record_type("UIHelpers.DropdownOptions", [], DropdownOptions, () => [["Id", string_type], ["DropdownContentHeader", class_type("Fable.React.ReactElement")], ["CustomClasses", list_type(string_type)], ["TriggerElement", class_type("Fable.React.ReactElement")], ["CloseDropdownOnClick", bool_type], ["DropdownItems", list_type(tuple_type(class_type("Fable.React.ReactElement"), string_type))]]);
}

export function DropdownOptions_get_Default() {
    return new DropdownOptions("", null, empty(), null, false, empty());
}

export class ModalOptions extends Record {
    constructor(Title, OnClose, Content, IsOpen) {
        super();
        this.Title = Title;
        this.OnClose = OnClose;
        this.Content = Content;
        this.IsOpen = IsOpen;
    }
}

export function ModalOptions$reflection() {
    return record_type("UIHelpers.ModalOptions", [], ModalOptions, () => [["Title", class_type("Fable.React.ReactElement")], ["OnClose", option_type(lambda_type(class_type("Browser.Types.MouseEvent", void 0, MouseEvent), unit_type))], ["Content", class_type("Fable.React.ReactElement")], ["IsOpen", bool_type]]);
}

export function ModalOptions_get_Default() {
    return new ModalOptions(null, void 0, null, false);
}

export const UI_loadingIndicator = createElement("span", {
    className: join(" ", ["fa", "fa-2x", "fa-spin", "fa-spinner"]),
});

export function UI_icon(options) {
    return createElement("span", createObj(toList(delay_1(() => append((!isNullOrEmpty(options.Title)) ? singleton(["title", options.Title]) : empty_1(), delay_1(() => append(singleton(["className", "icon"]), delay_1(() => {
        let elems;
        return singleton((elems = [createElement("i", {
            className: join(" ", options.IconClasses),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]));
    }))))))));
}

export function UI_button(options) {
    return createElement("button", createObj(toList(delay_1(() => append((options.Id !== "") ? singleton(["id", options.Id]) : empty_1(), delay_1(() => append(singleton(["type", "button"]), delay_1(() => append(singleton(["className", join(" ", cons("button", options.CustomClasses))]), delay_1(() => append(singleton(["onClick", options.OnClick]), delay_1(() => append(singleton(["disabled", options.Disabled]), delay_1(() => {
        let elems;
        return singleton((elems = toList(delay_1(() => {
            const matchValue = [options.Text, options.IconClasses];
            return isEmpty(matchValue[1]) ? singleton(createElement("span", {
                children: [matchValue[0]],
            })) : ((matchValue[0] === "") ? singleton(UI_icon(new IconOptions(IconOptions_get_Default().Title, matchValue[1]))) : singleton(toArray(ofArray([UI_icon(new IconOptions(IconOptions_get_Default().Title, matchValue[1])), createElement("span", {
                children: [matchValue[0]],
            })]))));
        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))]));
    }))))))))))))));
}

export function UI_textWithSub(options) {
    let elems;
    return createElement("span", createObj(ofArray([["style", {
        whiteSpace: "nowrap",
    }], (elems = [options.Text, createElement("sub", {
        children: [options.Sub],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function UI_dropdown(options) {
    let elems, elems_1;
    const dropdownRef = useReact_useRef_1505(void 0);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setActive = patternInput[1];
    const isActive = patternInput[0];
    react.useEffect(() => {
        const disp = (() => {
        let f;
        return ((f = ((ev) => {
            iterate((dropdownEl) => {
                if (!dropdownEl.contains(ev.target)) {
                    setActive(false);
                }
            }, toArray_1(dropdownRef.current));
        }), (node) => ((eventType) => {
            node.addEventListener(eventType, f);
            return {
                Dispose() {
                    node.removeEventListener(eventType, f);
                },
            };
        })))(document)("mousedown");
    })();
        return () => disp.Dispose();
    }, []);
    let content;
    const dropdownContentHeader = createElement("div", {
        className: join(" ", ["dropdown-item"]),
        children: options.DropdownContentHeader,
    });
    content = createElement("div", createObj(ofArray([["className", "dropdown-content"], (elems = toList(delay_1(() => append(singleton(dropdownContentHeader), delay_1(() => map((item_1) => {
        const tupledArg = item_1;
        return createElement("div", {
            className: join(" ", ["dropdown-item", "is-clickable"]),
            onClick: (_arg1) => {
                if (options.CloseDropdownOnClick) {
                    setActive(false);
                }
                window.location.href = tupledArg[1];
            },
            children: tupledArg[0],
        });
    }, options.DropdownItems))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    return createElement("div", createObj(ofArray([["ref", dropdownRef], ["className", join(" ", toList(delay_1(() => append(options.CustomClasses, delay_1(() => append(singleton("dropdown"), delay_1(() => (isActive ? singleton("is-active") : empty_1()))))))))], (elems_1 = [createElement("div", {
        className: "dropdown-trigger",
        ["aria-haspopup"]: true,
        ["aria-controls"]: join(" ", [options.Id]),
        onClick: (_arg2) => {
            setActive(!isActive);
        },
        children: options.TriggerElement,
    }), createElement("div", {
        className: "dropdown-menu",
        id: options.Id,
        role: join(" ", ["menu"]),
        children: content,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export class UI_RequiredSelectOptions extends Record {
    constructor(Id, Entries, InitialValue, OnChange) {
        super();
        this.Id = Id;
        this.Entries = Entries;
        this.InitialValue = InitialValue;
        this.OnChange = OnChange;
    }
}

export function UI_RequiredSelectOptions$reflection() {
    return record_type("UIHelpers.UI.RequiredSelectOptions", [], UI_RequiredSelectOptions, () => [["Id", string_type], ["Entries", list_type(tuple_type(string_type, string_type))], ["InitialValue", string_type], ["OnChange", lambda_type(string_type, unit_type)]]);
}

export function UI_requiredSelect(options) {
    let elems_1;
    const patternInput = useFeliz_React__React_useState_Static_1505(options.InitialValue);
    return createElement("select", createObj(ofArray([["id", options.Id], ["value", patternInput[0]], ["onChange", (ev) => {
        const v = ev.target.value;
        patternInput[1](v);
        options.OnChange(v);
    }], (elems_1 = toList(delay_1(() => map((kvPair) => {
        const tupledArg = kvPair;
        return createElement("option", {
            value: tupledArg[0],
            children: Interop_reactApi.Children.toArray([tupledArg[1]]),
        });
    }, options.Entries))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function UI_modal(options) {
    let elems_2, elems_1, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", toList(delay_1(() => append(singleton("modal"), delay_1(() => append(singleton("is-active"), delay_1(() => ((!options.IsOpen) ? singleton("is-hidden") : empty_1()))))))))], (elems_2 = [createElement("div", {
        className: "modal-background",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["modal-card"])], (elems_1 = [createElement("header", createObj(ofArray([["className", join(" ", ["modal-card-head"])], (elems = toList(delay_1(() => append(singleton(createElement("div", {
        className: join(" ", ["modal-card-title"]),
        children: options.Title,
    })), delay_1(() => {
        const matchValue = options.OnClose;
        if (matchValue == null) {
            return singleton(null);
        }
        else {
            const action = matchValue;
            return singleton(createElement("span", {
                className: join(" ", ["fa", "fa-lg", "fa-times", "is-clickable", "color-gray"]),
                onClick: action,
            }));
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("section", {
        className: join(" ", ["modal-card-body"]),
        children: options.Content,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function Layout_groupWithLabel(txt, control) {
    let elems;
    return createElement("div", createObj(ofArray([["className", "field"], (elems = [createElement("label", {
        className: "label",
        children: txt,
    }), createElement("div", {
        className: join(" ", ["control"]),
        children: control,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Layout_groupElements(elements) {
    let elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["field", "has-addons"])], ["style", {
        alignItems: "center",
    }], (elems = toList(delay_1(() => map((el) => createElement("p", {
        className: "control",
        children: el,
    }), elements))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Notification_error(errorMessage) {
    let builder_4, builder_1;
    return error((builder_4 = withCloseButton(dismissOnClick((builder_1 = position(new Position(3), message_1(errorMessage)), timeout(fromSeconds(15), builder_1)))), icon_1(toText(printf("%s color-error"))("fa-exclamation-triangle"), builder_4)));
}

export function Notification_info(infoMessage) {
    let builder_4, builder_1;
    return info((builder_4 = withCloseButton(dismissOnClick((builder_1 = position(new Position(3), message_1(infoMessage)), timeout(fromSeconds(15), builder_1)))), icon_1(toText(printf("%s color-info"))("fa-info-circle"), builder_4)));
}

export const Utils_browserLanguage = (substring(navigator.language, 0, 2).toLocaleLowerCase() === "de") ? (new Language(0)) : (new Language(1));

export const Utils_measurementUnits = (navigator.language.toLocaleLowerCase() === "en-us") ? (new MeasurementUnits(1)) : (new MeasurementUnits(0));

export function Utils_getCookie(name) {
    return tryFind(name, ofArray_1(map_1((s) => {
        const kvArr = split(s.trim(), ["="], null, 0);
        if ((!equalsWith((x, y) => (x === y), kvArr, null)) && (kvArr.length === 2)) {
            const v = kvArr[1];
            return [kvArr[0], v];
        }
        else {
            return ["", ""];
        }
    }, split(document.cookie, [";"], null, 0))));
}

export function Utils_setCookie(name, value) {
    const expiration = ((((60 * 60) * 24) * 365) * 5) | 0;
    document.cookie = (`${name}=${value}; max-age=${expiration}`);
}

