import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.7.11/Util.js";
import { tosText, imprintText } from "../Shared/HtmlIds.fs.js";
import { Strings__get_CookiePolicy_Link, Strings__get_TermsOfService_Text, Strings__get_TermsOfService_Title, Strings__get_Imprint } from "../Translations/Translations.fs.js";
import { singleton, ofArray } from "./fable_modules/fable-library.3.7.11/List.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/./Interop.fs.js";
import { useReact_useEffectOnce_3A5B6456 } from "./fable_modules/Feliz.1.64.0/React.fs.js";

export function Imprint_view(strings) {
    let content;
    return createElement("div", createObj(ofArray([["id", imprintText], (content = Strings__get_Imprint(strings), ["dangerouslySetInnerHTML", {
        __html: content,
    }])])));
}

export function TermsOfService_view(strings) {
    let elems, value_2, content;
    return createElement("div", createObj(ofArray([["id", tosText], (elems = [(value_2 = Strings__get_TermsOfService_Title(strings), createElement("h1", {
        children: [value_2],
    })), createElement("div", createObj(singleton((content = Strings__get_TermsOfService_Text(strings), ["dangerouslySetInnerHTML", {
        __html: content,
    }]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Cookiebot_addCookieDeclarationScript() {
    const script = document.createElement("script");
    script.setAttribute("id", "CookieDeclaration");
    script.setAttribute("src", "https://consent.cookiebot.com/2a4d9c4c-90ef-48ce-88f1-98c6311c5b47/cd.js");
    const page = document.getElementById("cookie-policy-page");
    page.appendChild(script);
}

export function CookieDeclaration(cookieDeclarationInputProps) {
    let elems_1, elems;
    const strings = cookieDeclarationInputProps.strings;
    useReact_useEffectOnce_3A5B6456(() => {
        Cookiebot_addCookieDeclarationScript();
    });
    return createElement("div", createObj(ofArray([["id", "cookie-policy-page"], ["className", "content"], (elems_1 = [createElement("h1", createObj(singleton((elems = [createElement("span", {
        className: "has-text-primary",
        children: Strings__get_CookiePolicy_Link(strings).toLocaleUpperCase(),
    }), createElement("br", {}), createElement("span", {
        children: ["FRONIUS INTERNATIONAL GMBH"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

