import { toString as toString_1, Record, Union } from "./fable_modules/fable-library.3.7.11/Types.js";
import { lambda_type, bool_type, unit_type, list_type, record_type, tuple_type, enum_type, option_type, float64_type, class_type, int32_type, union_type, string_type } from "./fable_modules/fable-library.3.7.11/Reflection.js";
import { parse } from "./fable_modules/fable-library.3.7.11/Int32.js";
import { MeasurementUnits_get_OfString, MeasurementUnits, MeasurementUnits_get_ToString, WeldingStatus_all, WeldingStatus_toString, WeldingProcess_all, WeldingProcess_toString, WeldingMode_all, WeldingMode_toString, EasyDocuFile, EasyDocuMetadata, EasyDocuHeaderData, GeneratePdfCommand, Language__get_Code, IEasyDocApi$reflection, Route_client, MeasurementUnits$reflection, EasyDocuHeaderData_get_Empty, EasyDocuOptions, EasyDocuOptions_get_Default, EasyDocuOptions$reflection, EasyDocuHeaderData$reflection, ServerError$reflection, EasyDocuFile$reflection, EasyDocuWeld } from "../Shared/Remoting.fs.js";
import { map, defaultArg } from "./fable_modules/fable-library.3.7.11/Option.js";
import { createObj, arrayHash, equalArrays, equals, int32ToString } from "./fable_modules/fable-library.3.7.11/Util.js";
import { Deferred_inProgress, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1, Deferred$1$reflection } from "./PageHelpers.fs.js";
import { concat, mapIndexed, find, item, iterate, exists, choose, length, singleton as singleton_1, append, ofArray, map as map_1, empty, isEmpty, filter } from "./fable_modules/fable-library.3.7.11/List.js";
import { UI_modal, IconOptions, UI_icon, ModalOptions, ModalOptions_get_Default, Layout_groupWithLabel, UI_requiredSelect, UI_RequiredSelectOptions, ButtonOptions, ButtonOptions_get_Default, UI_button, Notification_info, Notification_error, Utils_setCookie, Utils_getCookie, Utils_measurementUnits, Utils_browserLanguage } from "./UIHelpers.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.11/Choice.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder, Remoting_buildProxy_Z15584635 } from "./fable_modules/Fable.Remoting.Client.7.19.0/Remoting.fs.js";
import { Cmd_batch, Cmd_OfFunc_perform, Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton } from "./fable_modules/fable-library.3.7.11/AsyncBuilder.js";
import { ByteArrayExtensions_SaveFileAs_72B26259 } from "./fable_modules/Fable.Remoting.Client.7.19.0/Extensions.fs.js";
import { toString, now } from "./fable_modules/fable-library.3.7.11/Date.js";
import { join, endsWith, isNullOrWhiteSpace, printf, toText } from "./fable_modules/fable-library.3.7.11/String.js";
import { ServerError_explain, Cmd_OfAsync_eitherAsResult } from "./Remoting.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "./fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { GA_SendEvent_Z71ADD019 } from "./TagManager.fs.js";
import { Strings__get_CompatibleWith, Strings__get_EasyDocu_Helptext, Strings__get_TermsOfService_Text, Strings__get_Accept, Strings__get_TermsOfService_Title, Strings__get_Pdf_Report_Settings, Strings__get_Generate_Pdf, Strings__get_Btn_Cancel, Strings__get_Options, Strings__get_Show_Signature_Line, Strings__get_MeasurementUnits_Imperial, Strings__get_MeasurementUnits_Metric, Strings__get_MeasurementUnits, Strings__get_Remarks_Placeholder, Strings__get_Remarks, Strings__get_Welder_Placeholder, Strings__get_Welder, Strings__get_Order_Number_Placeholder, Strings__get_OrderNumber, Strings__get_Company_Placeholder, Strings__get_Company, Strings__get_Customer_Number_Placeholder, Strings__get_Customer_Number, Strings__get_Component_Placeholder, Strings__get_Component, Strings__get_Customer_Name_Placeholder, Strings__get_Customer_Name, Strings__get_Uploaded_Files, Strings__get_DND_Info, Strings__get_Clear_All_Files, Strings__get_Choose_EasyDocu_Files, Strings__get_NoRecords, Strings__get_AddRemoveColumns, Strings__get_ShowColumns, Strings__get_LastPage, Strings__get_FirstPage, Strings__get_PreviousPage, Strings__get_NextPage, Strings__get_Search, Strings__get_Welds, Strings__Error_Tooltip_Z721C83C5, Strings__get_Ignition_Tooltip, Strings__get_Passed_Tooltip, Strings__get_SeamNumber, Strings__get_Interval, Strings__get_Status, Strings__get_MotorCurrent, Strings__get_EasyJob, Strings__get_Process, Strings__get_Synergic_Line_Id, Strings__get_Mode, Strings__get_Energy, Strings__get_Power, Strings__get_WireFeedSpeed, Strings__get_Voltage, Strings__get_Current, Strings__get_Duration, Strings__get_Date, Strings__DuplicateFile_Z721C83C5, Strings__get_Only_CSV_Supported, Strings__get_Generate_Pdf_Failed, Strings__Could_Not_Load_File_Z721C83C5, Strings_$ctor_Z721C83C5 } from "../Translations/Translations.fs.js";
import { collect, map as map_2, empty as empty_1, singleton as singleton_2, append as append_1, delay, toList } from "./fable_modules/fable-library.3.7.11/Seq.js";
import { List_except } from "./fable_modules/fable-library.3.7.11/Seq2.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/./Interop.fs.js";
import { WeldingTableColumn, metricColumns, imperialColumns } from "../Shared/WeldingTable.fs.js";
import { UI_gridComponent, GridProps$1, ColumnProps, TableDefinition$1, GridTranslations, GridId } from "./Grid.fs.js";
import { rangeDouble } from "./fable_modules/fable-library.3.7.11/Range.js";
import { openPdfModal, weldsTable as weldsTable_1, tosText, acceptTos as acceptTos_1, generatePdf as generatePdf_1, cancelButton, showSignatureLine, measurementUnits, remarks, welder, orderNumber, company, customerNumber, newComponent, customerName, clearAllFiles, addFiles, fileUpload } from "../Shared/HtmlIds.fs.js";

export class NotificationMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ErrorMsg", "InfoMsg"];
    }
}

export function NotificationMsg$reflection() {
    return union_type("EasyDocu.NotificationMsg", [], NotificationMsg, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export class EasyDocuWeldWithWeldingId extends Record {
    constructor(SeamNumber, Date$, Duration, Current, Voltage, WireFeedMetric, WireFeedImperial, Power, Energy, MotorCurrent, SynergicLineId, EasyJob, Process, Mode, Status, StatusCode, Interval, WeldingId) {
        super();
        this.SeamNumber = (SeamNumber | 0);
        this.Date = Date$;
        this.Duration = Duration;
        this.Current = Current;
        this.Voltage = Voltage;
        this.WireFeedMetric = WireFeedMetric;
        this.WireFeedImperial = WireFeedImperial;
        this.Power = Power;
        this.Energy = Energy;
        this.MotorCurrent = MotorCurrent;
        this.SynergicLineId = (SynergicLineId | 0);
        this.EasyJob = EasyJob;
        this.Process = (Process | 0);
        this.Mode = Mode;
        this.Status = (Status | 0);
        this.StatusCode = StatusCode;
        this.Interval = Interval;
        this.WeldingId = WeldingId;
    }
}

export function EasyDocuWeldWithWeldingId$reflection() {
    return record_type("EasyDocu.EasyDocuWeldWithWeldingId", [], EasyDocuWeldWithWeldingId, () => [["SeamNumber", int32_type], ["Date", class_type("System.DateTime")], ["Duration", class_type("System.TimeSpan")], ["Current", float64_type], ["Voltage", float64_type], ["WireFeedMetric", float64_type], ["WireFeedImperial", float64_type], ["Power", float64_type], ["Energy", float64_type], ["MotorCurrent", float64_type], ["SynergicLineId", int32_type], ["EasyJob", option_type(int32_type)], ["Process", enum_type("Remoting.WeldingProcess.WeldingProcess", int32_type, [["GMAW_MAN", 0], ["GMAW_STD", 1], ["GMAW_PULSE", 2], ["GMAW_STD_S", 3], ["GMAW_PULSE_S", 4], ["STICK", 5], ["TIG", 6], ["TIG_PULSE", 7]])], ["Mode", option_type(enum_type("Remoting.WeldingMode.WeldingMode", int32_type, [["Mode_2T", 0], ["Mode_S2T", 1], ["Mode_4T", 2], ["Mode_S4T", 3], ["Mode_SPOT", 4]]))], ["Status", enum_type("Remoting.WeldingStatus.WeldingStatus", int32_type, [["Completed", 0], ["Ign", 1], ["Err", 2]])], ["StatusCode", option_type(int32_type)], ["Interval", string_type], ["WeldingId", tuple_type(int32_type, int32_type)]]);
}

export function toWelding(weldingWithId) {
    let matchValue;
    return new EasyDocuWeld(weldingWithId.SeamNumber, weldingWithId.Date, weldingWithId.Duration, weldingWithId.Current, weldingWithId.Voltage, weldingWithId.WireFeedMetric, weldingWithId.WireFeedImperial, weldingWithId.Power, weldingWithId.Energy, weldingWithId.MotorCurrent, weldingWithId.SynergicLineId, weldingWithId.EasyJob, weldingWithId.Process, weldingWithId.Mode, weldingWithId.Status, weldingWithId.StatusCode, (matchValue = weldingWithId.Interval, (matchValue === "") ? (void 0) : parse(matchValue, 511, false, 32)));
}

export function Remoting_EasyDocuWeld__EasyDocuWeld_ToEasyDocuWeldWithWeldingId_Z3227AE51(this$, weldingId) {
    return new EasyDocuWeldWithWeldingId(this$.SeamNumber, this$.Date, this$.Duration, this$.Current, this$.Voltage, this$.WireFeedMetric, this$.WireFeedImperial, this$.Power, this$.Energy, this$.MotorCurrent, this$.SynergicLineId, this$.EasyJob, this$.Process, this$.Mode, this$.Status, this$.StatusCode, defaultArg(map(int32ToString, this$.Interval), ""), weldingId);
}

export class CsvFile extends Record {
    constructor(FileName, Content) {
        super();
        this.FileName = FileName;
        this.Content = Content;
    }
}

export function CsvFile$reflection() {
    return record_type("EasyDocu.CsvFile", [], CsvFile, () => [["FileName", string_type], ["Content", string_type]]);
}

export class ValidationStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ValidFile", "InvalidFile"];
    }
}

export function ValidationStatus$reflection() {
    return union_type("EasyDocu.ValidationStatus", [], ValidationStatus, () => [[["Item", EasyDocuFile$reflection()]], [["Item", ServerError$reflection()]]]);
}

export class Model extends Record {
    constructor(Files, SelectedWelds, HeaderData, Options, GeneratePdfState, GeneratePdfModalDialogOpen, HighlightDropzone, TermsOfServiceAccepted) {
        super();
        this.Files = Files;
        this.SelectedWelds = SelectedWelds;
        this.HeaderData = HeaderData;
        this.Options = Options;
        this.GeneratePdfState = GeneratePdfState;
        this.GeneratePdfModalDialogOpen = GeneratePdfModalDialogOpen;
        this.HighlightDropzone = HighlightDropzone;
        this.TermsOfServiceAccepted = TermsOfServiceAccepted;
    }
}

export function Model$reflection() {
    return record_type("EasyDocu.Model", [], Model, () => [["Files", list_type(tuple_type(CsvFile$reflection(), ValidationStatus$reflection()))], ["SelectedWelds", list_type(tuple_type(int32_type, int32_type))], ["HeaderData", EasyDocuHeaderData$reflection()], ["Options", EasyDocuOptions$reflection()], ["GeneratePdfState", Deferred$1$reflection(unit_type)], ["GeneratePdfModalDialogOpen", bool_type], ["HighlightDropzone", bool_type], ["TermsOfServiceAccepted", bool_type]]);
}

export function Model__get_HasValidFiles(this$) {
    return !isEmpty(filter((x) => {
        if (x[1].tag === 0) {
            return true;
        }
        else {
            return false;
        }
    }, this$.Files));
}

export function Model_get_Initial() {
    let options;
    const inputRecord = EasyDocuOptions_get_Default();
    options = (new EasyDocuOptions(Utils_browserLanguage, Utils_measurementUnits, inputRecord.ShowFroniusLogo, inputRecord.ShowSignatureLine));
    return new Model(empty(), empty(), EasyDocuHeaderData_get_Empty(), options, new Deferred$1(0), false, false, equals(Utils_getCookie("tosAccepted"), "yes"));
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RemoveFile", "ClearFiles", "AddFile", "AddFileError", "ValidatedFile", "InvalidFile", "SetSelectedWelds", "GeneratePdf", "SetCustomerName", "SetCustomerNumber", "SetOrderNumber", "SetComponent", "SetWelder", "SetCompany", "SetRemarks", "SetShowFroniusLogo", "SetShowSignatureLine", "SetMeasurementUnit", "OpenGeneratePdfModal", "CloseGeneratePdfModal", "HighlightDropzone", "AcceptTos", "TosAccepted", "ResetFinished"];
    }
}

export function Msg$reflection() {
    return union_type("EasyDocu.Msg", [], Msg, () => [[["Item1", CsvFile$reflection()], ["Item2", ValidationStatus$reflection()]], [], [["Item", CsvFile$reflection()]], [["Item", string_type]], [["Item1", CsvFile$reflection()], ["Item2", EasyDocuFile$reflection()]], [["Item1", CsvFile$reflection()], ["Item2", ServerError$reflection()]], [["Item", list_type(tuple_type(int32_type, int32_type))]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ServerError$reflection()]]]))]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", MeasurementUnits$reflection()]], [], [], [["Item", bool_type]], [], [], []]);
}

export function unselectWelds() {
    const checkboxClasses = document.querySelector("#welds-table table thead tr th span").classList;
    const checkbox = document.querySelector("#welds-table table thead tr th span span input");
    if (checkboxClasses.contains("Mui-checked")) {
        checkbox.click();
    }
    if (checkboxClasses.contains("MuiCheckbox-indeterminate")) {
        checkbox.click();
        checkbox.click();
    }
}

export function resetFilter() {
    const filterTextBox = document.querySelector("#welds-table button[aria-label=\u0027Clear Search\u0027]");
    filterTextBox.click();
}

export function resetTable() {
    resetFilter();
    unselectWelds();
}

export function resetHiddenFileInput() {
    const hiddenFileInput = document.getElementsByClassName("dnd-file-input")[0];
    return hiddenFileInput.value = '';;
}

export const easyDocApi = Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_client("/api", typeName, methodName), RemotingModule_createApi()), {
    ResolveType: IEasyDocApi$reflection,
});

export function init() {
    return [Model_get_Initial(), Cmd_none()];
}

export function downloadPdf(cmd) {
    return singleton.Delay(() => singleton.Bind(easyDocApi.generatePdf(cmd), (_arg1) => {
        let arg10, copyOfStruct;
        ByteArrayExtensions_SaveFileAs_72B26259(_arg1, (arg10 = ((copyOfStruct = now(), toString(copyOfStruct, "yyyyMMdd_HHmmss"))), toText(printf("EasyDocu_Report_%s.pdf"))(arg10)));
        return singleton.Zero();
    }));
}

export function reset() {
    return Cmd_OfFunc_perform(() => {
        resetTable();
    }, void 0, () => (new Msg(23)));
}

export function generatePdf(cmd) {
    return Cmd_OfAsync_eitherAsResult(downloadPdf, cmd, (arg) => (new Msg(7, new AsyncOperationStatus$1(1, arg))));
}

export const acceptTos = Cmd_OfAsyncWith_perform((x) => {
    Cmd_OfAsync_start(x);
}, () => singleton.Delay(() => {
    Utils_setCookie("tosAccepted", "yes");
    return singleton.Zero();
}), void 0, () => (new Msg(22)));

export function gaHeaders(hd) {
    return map_1((tupledArg) => {
        const value = (isNullOrWhiteSpace(tupledArg[1]) ? 0 : 1) | 0;
        return GA_SendEvent_Z71ADD019("pdf-generation", `header-${tupledArg[0]}`, void 0, value);
    }, ofArray([["company", hd.Company], ["component", hd.Component], ["customer-name", hd.CustomerName], ["customer-number", hd.CustomerNumber], ["order-number", hd.OrderNumber], ["remarks", hd.Remarks], ["welder", hd.Welder]]));
}

export function update(msg, model) {
    let csvFile, isCsvFile, fileAlreadyInList;
    const strings = Strings_$ctor_Z721C83C5(Language__get_Code(model.Options.Language));
    const updateHeaderData = (updater) => (new Model(model.Files, model.SelectedWelds, updater(model.HeaderData), model.Options, model.GeneratePdfState, model.GeneratePdfModalDialogOpen, model.HighlightDropzone, model.TermsOfServiceAccepted));
    const updateOptions = (updater_1) => (new Model(model.Files, model.SelectedWelds, model.HeaderData, updater_1(model.Options), model.GeneratePdfState, model.GeneratePdfModalDialogOpen, model.HighlightDropzone, model.TermsOfServiceAccepted));
    if (msg.tag === 3) {
        return [model, Notification_error(Strings__Could_Not_Load_File_Z721C83C5(strings, msg.fields[0]))];
    }
    else if (msg.tag === 4) {
        return [new Model(append(singleton_1([msg.fields[0], new ValidationStatus(0, msg.fields[1])]), model.Files), model.SelectedWelds, model.HeaderData, model.Options, model.GeneratePdfState, model.GeneratePdfModalDialogOpen, model.HighlightDropzone, model.TermsOfServiceAccepted), GA_SendEvent_Z71ADD019("file-upload", "weldings", void 0, length(msg.fields[1].Welds))];
    }
    else if (msg.tag === 5) {
        return [new Model(append(singleton_1([msg.fields[0], new ValidationStatus(1, msg.fields[1])]), model.Files), model.SelectedWelds, model.HeaderData, model.Options, model.GeneratePdfState, model.GeneratePdfModalDialogOpen, model.HighlightDropzone, model.TermsOfServiceAccepted), GA_SendEvent_Z71ADD019("file-upload", "invalid-csv")];
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Model(model.Files, model.SelectedWelds, model.HeaderData, model.Options, new Deferred$1(0), false, model.HighlightDropzone, model.TermsOfServiceAccepted), Cmd_batch(ofArray([Notification_error(Strings__get_Generate_Pdf_Failed(strings)), GA_SendEvent_Z71ADD019("pdf-generation", "failure")]))];
            }
            else {
                return [new Model(model.Files, model.SelectedWelds, model.HeaderData, model.Options, new Deferred$1(0), false, model.HighlightDropzone, model.TermsOfServiceAccepted), Cmd_batch(ofArray([GA_SendEvent_Z71ADD019("pdf-generation", "success"), reset()]))];
            }
        }
        else {
            const cmdDef = new GeneratePdfCommand(model.Options, model.HeaderData, choose((tupledArg) => {
                if (tupledArg[1].tag === 0) {
                    return tupledArg[0].Content;
                }
                else {
                    return void 0;
                }
            }, model.Files), model.SelectedWelds);
            return [new Model(model.Files, model.SelectedWelds, model.HeaderData, model.Options, new Deferred$1(1), model.GeneratePdfModalDialogOpen, model.HighlightDropzone, model.TermsOfServiceAccepted), Cmd_batch(toList(delay(() => append_1(singleton_2(generatePdf(cmdDef)), delay(() => append_1(singleton_2(GA_SendEvent_Z71ADD019("pdf-generation", "upload-weldings", void 0, length(model.SelectedWelds))), delay(() => gaHeaders(model.HeaderData))))))))];
        }
    }
    else if (msg.tag === 6) {
        return [new Model(model.Files, msg.fields[0], model.HeaderData, model.Options, model.GeneratePdfState, model.GeneratePdfModalDialogOpen, model.HighlightDropzone, model.TermsOfServiceAccepted), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [updateHeaderData((headerData) => (new EasyDocuHeaderData(msg.fields[0], headerData.CustomerNumber, headerData.OrderNumber, headerData.Remarks, headerData.Component, headerData.Company, headerData.Welder))), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [updateHeaderData((headerData_1) => (new EasyDocuHeaderData(headerData_1.CustomerName, msg.fields[0], headerData_1.OrderNumber, headerData_1.Remarks, headerData_1.Component, headerData_1.Company, headerData_1.Welder))), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [updateHeaderData((headerData_2) => (new EasyDocuHeaderData(headerData_2.CustomerName, headerData_2.CustomerNumber, msg.fields[0], headerData_2.Remarks, headerData_2.Component, headerData_2.Company, headerData_2.Welder))), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [updateHeaderData((headerData_3) => (new EasyDocuHeaderData(headerData_3.CustomerName, headerData_3.CustomerNumber, headerData_3.OrderNumber, headerData_3.Remarks, msg.fields[0], headerData_3.Company, headerData_3.Welder))), Cmd_none()];
    }
    else if (msg.tag === 12) {
        return [updateHeaderData((headerData_4) => (new EasyDocuHeaderData(headerData_4.CustomerName, headerData_4.CustomerNumber, headerData_4.OrderNumber, headerData_4.Remarks, headerData_4.Component, headerData_4.Company, msg.fields[0]))), Cmd_none()];
    }
    else if (msg.tag === 13) {
        return [updateHeaderData((headerData_5) => (new EasyDocuHeaderData(headerData_5.CustomerName, headerData_5.CustomerNumber, headerData_5.OrderNumber, headerData_5.Remarks, headerData_5.Component, msg.fields[0], headerData_5.Welder))), Cmd_none()];
    }
    else if (msg.tag === 14) {
        return [updateHeaderData((headerData_6) => (new EasyDocuHeaderData(headerData_6.CustomerName, headerData_6.CustomerNumber, headerData_6.OrderNumber, msg.fields[0], headerData_6.Component, headerData_6.Company, headerData_6.Welder))), Cmd_none()];
    }
    else if (msg.tag === 15) {
        return [updateOptions((opts) => (new EasyDocuOptions(opts.Language, opts.MeasurementUnits, msg.fields[0], opts.ShowSignatureLine))), Cmd_none()];
    }
    else if (msg.tag === 16) {
        return [updateOptions((opts_1) => (new EasyDocuOptions(opts_1.Language, opts_1.MeasurementUnits, opts_1.ShowFroniusLogo, msg.fields[0]))), Cmd_none()];
    }
    else if (msg.tag === 17) {
        return [updateOptions((opts_2) => (new EasyDocuOptions(opts_2.Language, msg.fields[0], opts_2.ShowFroniusLogo, opts_2.ShowSignatureLine))), Cmd_none()];
    }
    else if (msg.tag === 0) {
        resetHiddenFileInput();
        return [new Model(List_except([[msg.fields[0], msg.fields[1]]], model.Files, {
            Equals: equalArrays,
            GetHashCode: arrayHash,
        }), empty(), model.HeaderData, model.Options, model.GeneratePdfState, model.GeneratePdfModalDialogOpen, model.HighlightDropzone, model.TermsOfServiceAccepted), Cmd_none()];
    }
    else if (msg.tag === 1) {
        resetHiddenFileInput();
        return [new Model(empty(), empty(), model.HeaderData, model.Options, model.GeneratePdfState, model.GeneratePdfModalDialogOpen, model.HighlightDropzone, model.TermsOfServiceAccepted), Cmd_none()];
    }
    else if (msg.tag === 18) {
        return [new Model(model.Files, model.SelectedWelds, model.HeaderData, model.Options, model.GeneratePdfState, true, model.HighlightDropzone, model.TermsOfServiceAccepted), Cmd_none()];
    }
    else if (msg.tag === 19) {
        return [new Model(model.Files, model.SelectedWelds, model.HeaderData, model.Options, model.GeneratePdfState, false, model.HighlightDropzone, model.TermsOfServiceAccepted), Cmd_none()];
    }
    else if (msg.tag === 20) {
        return [new Model(model.Files, model.SelectedWelds, model.HeaderData, model.Options, model.GeneratePdfState, model.GeneratePdfModalDialogOpen, msg.fields[0], model.TermsOfServiceAccepted), Cmd_none()];
    }
    else if (msg.tag === 21) {
        return [new Model(model.Files, model.SelectedWelds, model.HeaderData, model.Options, model.GeneratePdfState, model.GeneratePdfModalDialogOpen, model.HighlightDropzone, true), acceptTos];
    }
    else if (msg.tag === 22) {
        return [new Model(model.Files, model.SelectedWelds, model.HeaderData, model.Options, model.GeneratePdfState, model.GeneratePdfModalDialogOpen, model.HighlightDropzone, true), Cmd_none()];
    }
    else if (msg.tag === 23) {
        return [model, Cmd_none()];
    }
    else {
        return [model, (csvFile = msg.fields[0], (isCsvFile = endsWith(csvFile.FileName.toLocaleLowerCase(), ".csv"), (fileAlreadyInList = exists((_arg1) => {
            if (_arg1[1].tag === 1) {
                return _arg1[0].FileName === csvFile.FileName;
            }
            else {
                return _arg1[0].FileName === csvFile.FileName;
            }
        }, model.Files), (!isCsvFile) ? Cmd_batch(ofArray([Notification_error(Strings__get_Only_CSV_Supported(strings)), GA_SendEvent_Z71ADD019("file-upload", "non-csv-added")])) : (fileAlreadyInList ? Cmd_batch(ofArray([Notification_info(Strings__DuplicateFile_Z721C83C5(strings, csvFile.FileName)), GA_SendEvent_Z71ADD019("file-upload", "duplicate-csv-added")])) : Cmd_OfAsync_eitherAsResult((x) => easyDocApi.validateCsv(x.Content), csvFile, (_arg2) => {
            let inputRecord;
            if (_arg2.tag === 1) {
                return new Msg(5, csvFile, _arg2.fields[0]);
            }
            else {
                const easyDoc = _arg2.fields[0];
                return new Msg(4, csvFile, new EasyDocuFile((inputRecord = easyDoc.Metadata, new EasyDocuMetadata(inputRecord.Machine, csvFile.FileName, inputRecord.ArticleNumber, inputRecord.SerialNumber, inputRecord.FirmwarePowersource, inputRecord.FirmwareDocMag, inputRecord.SignatureType)), easyDoc.Welds));
            }
        })))))];
    }
}

export function Bulma_h1(text) {
    return createElement("h1", {
        className: join(" ", ["title"]),
        children: text,
    });
}

export const Bulma_control = (children) => createElement("div", {
    className: join(" ", ["control"]),
    children: Interop_reactApi.Children.toArray(Array.from(children)),
});

export const Bulma_field = (children) => createElement("div", {
    className: join(" ", ["field"]),
    children: Interop_reactApi.Children.toArray(Array.from(children)),
});

export class Bulma_InputProps extends Record {
    constructor(Id, Label, Placeholder, ValueOrDefault, OnTextChange, MaxLength) {
        super();
        this.Id = Id;
        this.Label = Label;
        this.Placeholder = Placeholder;
        this.ValueOrDefault = ValueOrDefault;
        this.OnTextChange = OnTextChange;
        this.MaxLength = MaxLength;
    }
}

export function Bulma_InputProps$reflection() {
    return record_type("EasyDocu.Bulma.InputProps", [], Bulma_InputProps, () => [["Id", string_type], ["Label", string_type], ["Placeholder", string_type], ["ValueOrDefault", string_type], ["OnTextChange", lambda_type(string_type, unit_type)], ["MaxLength", option_type(int32_type)]]);
}

export function Bulma_inputField(props) {
    return Bulma_field(ofArray([createElement("label", {
        className: join(" ", ["label"]),
        children: props.Label,
    }), Bulma_control(singleton_1(createElement("input", createObj(toList(delay(() => append_1(singleton_2(["id", props.Id]), delay(() => append_1(singleton_2(["className", join(" ", ["input"])]), delay(() => append_1(singleton_2(["type", "text"]), delay(() => append_1(singleton_2(["placeholder", props.Placeholder]), delay(() => {
        let value_9;
        return append_1(singleton_2((value_9 = props.ValueOrDefault, ["ref", (e) => {
            if ((!(e == null)) && (!equals(e.value, value_9))) {
                e.value = value_9;
            }
        }])), delay(() => append_1(singleton_2(["onChange", (ev) => {
            props.OnTextChange(ev.target.value);
        }]), delay(() => {
            const matchValue = props.MaxLength;
            if (matchValue == null) {
                return empty_1();
            }
            else {
                return singleton_2(["maxLength", matchValue]);
            }
        }))));
    }))))))))))))))]));
}

export class Bulma_CheckboxProps extends Record {
    constructor(Id, Label, Checked, OnCheckedChange, Disabled) {
        super();
        this.Id = Id;
        this.Label = Label;
        this.Checked = Checked;
        this.OnCheckedChange = OnCheckedChange;
        this.Disabled = Disabled;
    }
}

export function Bulma_CheckboxProps$reflection() {
    return record_type("EasyDocu.Bulma.CheckboxProps", [], Bulma_CheckboxProps, () => [["Id", string_type], ["Label", string_type], ["Checked", bool_type], ["OnCheckedChange", lambda_type(bool_type, unit_type)], ["Disabled", bool_type]]);
}

export function Bulma_checkbox(props) {
    let elems;
    return Bulma_field(singleton_1(createElement("label", createObj(ofArray([["className", join(" ", ["checkbox"])], (elems = [createElement("input", {
        id: props.Id,
        type: "checkbox",
        disabled: props.Disabled,
        onChange: (ev) => {
            props.OnCheckedChange(ev.target.checked);
        },
        checked: props.Checked,
        className: join(" ", ["mr-2"]),
    }), toText(printf(" %s"))(props.Label)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))));
}

export function view(strings, model, dispatch) {
    let elems_6, elems_8, elems_7, elems_11, elems_9, children, elems_10, elems_16, elems_14, elems_15, elems_19, elems_17, elems_18, elems_22, elems_20, elems_21, elems_26, elems_23, elems_25, elems_24, selectOptions, InitialValue, elems_13, elems_28, elems_27, inputRecord_2, inputRecord_3, Text$_3, elems_31, elems_33, elems_34, inputRecord_5, content_1, elems_35, children_7, arg10, elems_36, elems_37, inputRecord_7;
    const readFiles = (files) => {
        iterate((arg00) => {
            const f = arg00;
            const fr = new FileReader();
            fr.onload = ((ev) => {
                dispatch(new Msg(2, new CsvFile(f.name, ev.target.result)));
            });
            fr.onerror = ((_arg1) => {
                dispatch(new Msg(3, f.name));
            });
            fr.readAsText(f);
        }, files);
        return resetHiddenFileInput();
    };
    const inlineUnits = true;
    let weldsTable;
    const columns = (model.Options.MeasurementUnits.tag === 1) ? imperialColumns((d_1) => toString(d_1, "dd.MM.yyyy HH:mm:ss"), inlineUnits) : metricColumns((d) => toString(d, "dd.MM.yyyy HH:mm:ss"), inlineUnits);
    let definition;
    const getCol = (col) => item(col, columns).column;
    const findColumn = (col_1) => {
        const wtCol = getCol(col_1);
        return find((x) => equals(x.column, wtCol), columns);
    };
    definition = (new TableDefinition$1(new GridId(0), (colIdx_1) => {
        let elems;
        const column = findColumn(colIdx_1);
        let tupledArg;
        const matchValue_2 = column.column;
        switch (matchValue_2.tag) {
            case 1: {
                tupledArg = ["pw-icon-date", Strings__get_Date(strings)];
                break;
            }
            case 2: {
                tupledArg = ["pw-icon-duration", Strings__get_Duration(strings)];
                break;
            }
            case 5: {
                tupledArg = ["pw-icon-i", Strings__get_Current(strings)];
                break;
            }
            case 6: {
                tupledArg = ["pw-icon-u", Strings__get_Voltage(strings)];
                break;
            }
            case 3:
            case 4: {
                tupledArg = ["pw-icon-wirefeeder", Strings__get_WireFeedSpeed(strings)];
                break;
            }
            case 7: {
                tupledArg = ["pw-icon-ip", Strings__get_Power(strings)];
                break;
            }
            case 8: {
                tupledArg = ["pw-icon-ie", Strings__get_Energy(strings)];
                break;
            }
            case 10: {
                tupledArg = ["pw-icon-mode", Strings__get_Mode(strings)];
                break;
            }
            case 11: {
                tupledArg = ["pw-icon-Syn-ID", Strings__get_Synergic_Line_Id(strings)];
                break;
            }
            case 9: {
                tupledArg = ["pw-icon-process", Strings__get_Process(strings)];
                break;
            }
            case 12: {
                tupledArg = ["pw-icon-EasyJob", Strings__get_EasyJob(strings)];
                break;
            }
            case 13: {
                tupledArg = ["pw-icon-motorstrom", Strings__get_MotorCurrent(strings)];
                break;
            }
            case 14: {
                tupledArg = ["pw-icon-status", Strings__get_Status(strings)];
                break;
            }
            case 15: {
                tupledArg = ["pw-icon-interval", Strings__get_Interval(strings)];
                break;
            }
            default: {
                tupledArg = ["pw-icon-naht-nr", Strings__get_SeamNumber(strings)];
            }
        }
        const tooltip = tupledArg[1];
        return createElement("div", createObj(ofArray([["className", "col-sel-item-wrapper"], (elems = toList(delay(() => append_1(singleton_2(createElement("span", {
            className: join(" ", [tupledArg[0], "is-size-3"]),
            title: tooltip,
        })), delay(() => append_1(singleton_2(createElement("span", {
            className: join(" ", ["column-description"]),
            children: tooltip,
        })), delay(() => {
            const matchValue_1 = [inlineUnits, column.unit];
            let pattern_matching_result;
            if (matchValue_1[0]) {
                pattern_matching_result = 1;
            }
            else if (matchValue_1[1] != null) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    const u = matchValue_1[1];
                    return append_1(singleton_2(createElement("br", {})), delay(() => singleton_2(u)));
                }
                case 1: {
                    return empty_1();
                }
            }
        })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }, (weldingWithId, colIdx_2) => findColumn(colIdx_2).formatValue(toWelding(weldingWithId)), (weldingWithId_1, colIdx_3) => {
        const _arg1_1 = findColumn(colIdx_3);
        if (equals(_arg1_1.column, new WeldingTableColumn(14))) {
            const w = toWelding(weldingWithId_1);
            const matchValue_3 = _arg1_1.formatValue(w);
            if (matchValue_3 === "COMPLETED") {
                return createElement("span", {
                    className: join(" ", ["tag", "is-success"]),
                    children: "COMPLETED",
                    title: Strings__get_Passed_Tooltip(strings),
                });
            }
            else if (matchValue_3 === "IGNITION") {
                return createElement("span", {
                    className: join(" ", ["tag", "is-warning"]),
                    children: "IGNITION",
                    title: Strings__get_Ignition_Tooltip(strings),
                });
            }
            else if (matchValue_3.indexOf("ERROR") === 0) {
                return createElement("span", {
                    className: join(" ", ["tag", "is-danger"]),
                    children: matchValue_3,
                    title: Strings__Error_Tooltip_Z721C83C5(strings, int32ToString(defaultArg(w.StatusCode, -1))),
                });
            }
            else {
                return matchValue_3;
            }
        }
        else {
            return _arg1_1.formatValue(toWelding(weldingWithId_1));
        }
    }, (colIdx) => toString_1(getCol(colIdx)), (colInt) => {
        const matchValue_4 = getCol(colInt);
        switch (matchValue_4.tag) {
            case 0: {
                return ["type", "numeric"];
            }
            case 2: {
                return ["type", "numeric"];
            }
            case 5: {
                return ["type", "numeric"];
            }
            case 6: {
                return ["type", "numeric"];
            }
            case 3: {
                return ["type", "numeric"];
            }
            case 4: {
                return ["type", "numeric"];
            }
            case 7: {
                return ["type", "numeric"];
            }
            case 8: {
                return ["type", "numeric"];
            }
            case 11: {
                return ["type", "numeric"];
            }
            case 13: {
                return ["type", "numeric"];
            }
            case 15: {
                return ["type", "numeric"];
            }
            default: {
                return void 0;
            }
        }
    }, (colInt_1) => {
        const matchValue_5 = getCol(colInt_1);
        switch (matchValue_5.tag) {
            case 12: {
                return map_1((x_9) => [x_9, int32ToString(x_9)], toList(rangeDouble(1, 1, 5)));
            }
            case 10: {
                return map_1((x_10) => [x_10, WeldingMode_toString(x_10)], WeldingMode_all);
            }
            case 9: {
                return map_1((x_11) => [x_11, WeldingProcess_toString(x_11)], WeldingProcess_all);
            }
            case 14: {
                return map_1((ws) => [ws, WeldingStatus_toString(ws)], WeldingStatus_all);
            }
            default: {
                return void 0;
            }
        }
    }, (data) => int32ToString(arrayHash(data.WeldingId)), new GridTranslations(Strings__get_Welds(strings), Strings__get_Search(strings), Strings__get_NextPage(strings), Strings__get_PreviousPage(strings), Strings__get_FirstPage(strings), Strings__get_LastPage(strings), Strings__get_ShowColumns(strings), Strings__get_AddRemoveColumns(strings), Strings__get_NoRecords(strings))));
    const gridCols = mapIndexed((idx, c) => (new ColumnProps(idx, true, c.alignment)), columns);
    const gridProps = new GridProps$1(definition, concat(mapIndexed((fileIdx, file) => mapIndexed((weldIdx, weld) => Remoting_EasyDocuWeld__EasyDocuWeld_ToEasyDocuWeldWithWeldingId_Z3227AE51(weld, [fileIdx, weldIdx]), file.Welds), choose((_arg2) => {
        if (_arg2[1].tag === 0) {
            return _arg2[1].fields[0];
        }
        else {
            return void 0;
        }
    }, model.Files))), gridCols, true, (selectedItems, _arg2_1) => {
        dispatch(new Msg(6, map_1((x_12) => x_12.WeldingId, selectedItems)));
    });
    weldsTable = UI_gridComponent()(gridProps);
    let dndFileUploadControl;
    const hiddenFileInput = createElement("input", {
        id: fileUpload,
        className: join(" ", ["dnd-file-input", "is-hidden"]),
        type: "file",
        multiple: true,
        onChange: (ev_1) => {
            const fileList = ev_1.target.files;
            if (!(fileList == null)) {
                readFiles(toList(delay(() => map_2((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1)))));
            }
        },
    });
    const addFilesBtn = (customClasses) => UI_button(new ButtonOptions(addFiles, ButtonOptions_get_Default().Disabled, Strings__get_Choose_EasyDocu_Files(strings), customClasses, ofArray(["fa", "fa-plus"]), (evt) => {
        const hiddenFileInput_1 = document.getElementsByClassName("dnd-file-input")[0];
        hiddenFileInput_1.click();;
        evt.preventDefault();
    }));
    const clearAllFilesBtn = UI_button(new ButtonOptions(clearAllFiles, ButtonOptions_get_Default().Disabled, Strings__get_Clear_All_Files(strings), singleton_1("ml-2"), ofArray(["fa", "fa-ban"]), (_arg4) => {
        dispatch(new Msg(1));
    }));
    let initialAppearance;
    const iconCol = createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-2", "is-fullheight", "is-hidden-mobile"])], (elems_6 = [createElement("span", {
        style: {
            fontSize: 80 + "px",
        },
        className: join(" ", ["pw-icon-upload"]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
    initialAppearance = createElement("div", createObj(ofArray([["className", join(" ", ["initial", "columns", "is-marginless", "is-gapless", "is-fullheight", "has-text-centered", "is-vcentered"])], (elems_8 = [iconCol, createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-8", "is-fullheight"])], (elems_7 = [createElement("p", {
        children: Strings__get_DND_Info(strings),
        className: join(" ", ["mb-5", "is-size-5", "has-text-weight-bold"]),
    }), addFilesBtn(singleton_1("is-primary"))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), iconCol], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])));
    const appearanceWithFiles = createElement("div", createObj(ofArray([["className", "with-files"], (elems_11 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-justify-content-space-between"])], (elems_9 = [createElement("b", {
        children: Strings__get_Uploaded_Files(strings),
        className: "mr-2",
    }), (children = ofArray([addFilesBtn(empty()), clearAllFilesBtn]), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["dropzone-file-list"])], (elems_10 = toList(delay(() => map_2((file_2) => {
        let elems_3, elems_5, elems_1, elems_2;
        const file_1 = file_2;
        let deleteFileBtn;
        const icon_1 = createElement("span", {
            className: join(" ", ["fa", "fa-trash"]),
        });
        deleteFileBtn = createElement("a", {
            className: join(" ", ["delete-file", "ml-2"]),
            children: icon_1,
            onClick: (_arg3) => {
                let tupledArg_1;
                dispatch((tupledArg_1 = file_1, new Msg(0, tupledArg_1[0], tupledArg_1[1])));
            },
        });
        if (file_1[1].tag === 1) {
            const invalidIcon = createElement("span", createObj(ofArray([["style", {
                color: "#FF0000",
            }], (elems_3 = [createElement("span", {
                className: join(" ", ["pw-icon-nok", "is-size-4"]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
            return createElement("div", createObj(ofArray([["className", join(" ", ["dropzone-file", "invalid-file", "is-flex"])], ["title", ServerError_explain(strings, file_1[1].fields[0])], (elems_5 = [invalidIcon, createElement("span", {
                style: {
                    color: "#FF0000",
                },
                children: Interop_reactApi.Children.toArray([file_1[0].FileName]),
            }), deleteFileBtn], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])));
        }
        else {
            const validIcon = createElement("span", createObj(ofArray([["style", {
                color: "#008000",
            }], (elems_1 = [createElement("span", {
                className: join(" ", ["pw-icon-ok", "is-size-4"]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
            return createElement("div", createObj(ofArray([["className", join(" ", ["dropzone-file", "valid-file"])], (elems_2 = [validIcon, createElement("span", {
                children: [file_1[1].fields[0].Metadata.FileName],
            }), deleteFileBtn], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
        }
    }, model.Files))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])));
    const containerWith = (content) => createElement("div", {
        className: join(" ", toList(delay(() => append_1(singleton_2("dropzone"), delay(() => append_1(singleton_2("mb-5"), delay(() => (model.HighlightDropzone ? singleton_2("highlight") : empty_1())))))))),
        onDragOver: (ev_2) => {
            ev_2.preventDefault();
        },
        onDragEnter: (ev_3) => {
            if (ev_3.currentTarget.className === "dropzone mb-5") {
                dispatch(new Msg(20, true));
            }
        },
        onDragLeave: (ev_4) => {
            if (ev_4.target.className === "dropzone mb-5 highlight") {
                dispatch(new Msg(20, false));
            }
        },
        onDrop: (ev_5) => {
            readFiles(toList(delay(() => ((ev_5.dataTransfer.items.length > 0) ? collect((i_1) => (((ev_5.dataTransfer.items[i_1]).kind === "file") ? singleton_2((ev_5.dataTransfer.items[i_1]).getAsFile()) : empty_1()), toList(rangeDouble(0, 1, ev_5.dataTransfer.items.length - 1))) : map_2((i_2) => (ev_5.dataTransfer.files[i_2]), toList(rangeDouble(0, 1, ev_5.dataTransfer.files.length - 1)))))));
            dispatch(new Msg(20, false));
            ev_5.preventDefault();
        },
        children: Interop_reactApi.Children.toArray([content, hiddenFileInput]),
    });
    dndFileUploadControl = (isEmpty(model.Files) ? containerWith(initialAppearance) : containerWith(appearanceWithFiles));
    let modalContent;
    let headerData;
    const arg00_1 = ofArray([createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_16 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-half"])], (elems_14 = [Bulma_inputField(new Bulma_InputProps(customerName, Strings__get_Customer_Name(strings), Strings__get_Customer_Name_Placeholder(strings), model.HeaderData.CustomerName, (arg) => {
        dispatch(new Msg(8, arg));
    }, 35))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-half"])], (elems_15 = [Bulma_inputField(new Bulma_InputProps(newComponent, Strings__get_Component(strings), Strings__get_Component_Placeholder(strings), model.HeaderData.Component, (arg_1) => {
        dispatch(new Msg(11, arg_1));
    }, 35))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_19 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-half"])], (elems_17 = [Bulma_inputField(new Bulma_InputProps(customerNumber, Strings__get_Customer_Number(strings), Strings__get_Customer_Number_Placeholder(strings), model.HeaderData.CustomerNumber, (arg_2) => {
        dispatch(new Msg(9, arg_2));
    }, 35))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-half"])], (elems_18 = [Bulma_inputField(new Bulma_InputProps(company, Strings__get_Company(strings), Strings__get_Company_Placeholder(strings), model.HeaderData.Company, (arg_3) => {
        dispatch(new Msg(13, arg_3));
    }, 35))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_22 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-half"])], (elems_20 = [Bulma_inputField(new Bulma_InputProps(orderNumber, Strings__get_OrderNumber(strings), Strings__get_Order_Number_Placeholder(strings), model.HeaderData.OrderNumber, (arg_4) => {
        dispatch(new Msg(10, arg_4));
    }, 35))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-half"])], (elems_21 = [Bulma_inputField(new Bulma_InputProps(welder, Strings__get_Welder(strings), Strings__get_Welder_Placeholder(strings), model.HeaderData.Welder, (arg_5) => {
        dispatch(new Msg(12, arg_5));
    }, 35))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_26 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-half"])], (elems_23 = [Bulma_inputField(new Bulma_InputProps(remarks, Strings__get_Remarks(strings), Strings__get_Remarks_Placeholder(strings), model.HeaderData.Remarks, (arg_6) => {
        dispatch(new Msg(14, arg_6));
    }, 35))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-half"])], (elems_25 = [createElement("div", createObj(ofArray([["className", "field"], (elems_24 = [createElement("label", {
        className: "label",
        children: Strings__get_MeasurementUnits(strings),
    }), createElement("div", {
        className: join(" ", ["control", "is-expanded"]),
        children: (selectOptions = ((InitialValue = toString_1(model.Options.MeasurementUnits), new UI_RequiredSelectOptions(measurementUnits, ofArray([[MeasurementUnits_get_ToString()(new MeasurementUnits(0)), Strings__get_MeasurementUnits_Metric(strings)], [MeasurementUnits_get_ToString()(new MeasurementUnits(1)), Strings__get_MeasurementUnits_Imperial(strings)]]), InitialValue, (str) => {
            dispatch(new Msg(17, MeasurementUnits_get_OfString()(str)));
        }))), createElement("div", createObj(ofArray([["className", join(" ", ["select", "is-fullwidth"])], (elems_13 = [createElement(UI_requiredSelect, selectOptions)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_28 = [createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-full"])], (elems_27 = toList(delay(() => {
        const signatureCheckbox = Bulma_checkbox(new Bulma_CheckboxProps(showSignatureLine, Strings__get_Show_Signature_Line(strings), model.Options.ShowSignatureLine, (arg_7) => {
            dispatch(new Msg(16, arg_7));
        }, false));
        return singleton_2(Layout_groupWithLabel(Strings__get_Options(strings), signatureCheckbox));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))])])))]);
    headerData = react.createElement(react.Fragment, {}, ...arg00_1);
    let buttons;
    const cancelBtn = UI_button((inputRecord_2 = ButtonOptions_get_Default(), new ButtonOptions(cancelButton, false, Strings__get_Btn_Cancel(strings), empty(), inputRecord_2.IconClasses, (_arg5) => {
        dispatch(new Msg(19));
    })));
    const generatePdfBtn = UI_button((inputRecord_3 = ButtonOptions_get_Default(), (Text$_3 = Strings__get_Generate_Pdf(strings), new ButtonOptions(generatePdf_1, isEmpty(model.SelectedWelds) ? true : Deferred_inProgress(model.GeneratePdfState), Text$_3, singleton_1("is-primary"), inputRecord_3.IconClasses, (_arg6) => {
        dispatch(new Msg(7, new AsyncOperationStatus$1(0)));
    }))));
    buttons = createElement("div", createObj(ofArray([["className", "columns"], (elems_31 = [createElement("div", {
        className: join(" ", ["column", "is-half", "has-text-right"]),
        children: Interop_reactApi.Children.toArray([cancelBtn]),
    }), createElement("div", {
        className: join(" ", ["column", "is-half", "has-text-left"]),
        children: Interop_reactApi.Children.toArray([generatePdfBtn]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])])));
    modalContent = createElement("div", {
        className: join(" ", ["content"]),
        children: Interop_reactApi.Children.toArray([headerData, buttons]),
    });
    let generatePdfModalOptions;
    const title = createElement("div", createObj(ofArray([["className", join(" ", ["has-text-centered", "is-bold"])], (elems_33 = [createElement("span", {
        className: join(" ", ["pw-icon-PDF", "is-size-5", "mr-2"]),
    }), Strings__get_Pdf_Report_Settings(strings)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))])])));
    const inputRecord_4 = ModalOptions_get_Default();
    generatePdfModalOptions = (new ModalOptions(title, (_arg7) => {
        dispatch(new Msg(19));
    }, modalContent, model.GeneratePdfModalDialogOpen));
    let tosModalOptions;
    const title_1 = createElement("div", createObj(ofArray([["className", join(" ", ["has-text-centered", "is-bold"])], (elems_34 = [createElement("span", {
        className: join(" ", ["pw-icon-PDF", "is-size-5", "mr-2"]),
    }), Strings__get_TermsOfService_Title(strings)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])])));
    let content_2;
    const acceptBtn = UI_button((inputRecord_5 = ButtonOptions_get_Default(), new ButtonOptions(acceptTos_1, false, Strings__get_Accept(strings), singleton_1("is-primary"), inputRecord_5.IconClasses, (_arg8) => {
        dispatch(new Msg(21));
    })));
    const children_5 = ofArray([createElement("div", createObj(ofArray([["id", tosText], (content_1 = Strings__get_TermsOfService_Text(strings), ["dangerouslySetInnerHTML", {
        __html: content_1,
    }])]))), createElement("div", {
        children: Interop_reactApi.Children.toArray([acceptBtn]),
    })]);
    content_2 = createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_5)),
    });
    const inputRecord_6 = ModalOptions_get_Default();
    tosModalOptions = (new ModalOptions(title_1, void 0, content_2, !model.TermsOfServiceAccepted));
    const spinner = createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_2("loading"), delay(() => (Deferred_inProgress(model.GeneratePdfState) ? singleton_2("spinner-active") : empty_1()))))))], (elems_35 = [createElement("img", {
        src: "spinner_440px.svg",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])])));
    let formular;
    const children_9 = ofArray([(children_7 = ofArray([Strings__get_EasyDocu_Helptext(strings), UI_icon(new IconOptions((arg10 = Strings__get_CompatibleWith(strings), toText(printf("%s: TransSteel 3000/4000/5000 Pulse"))(arg10)), ofArray(["fa", "fa-info-circle", "color-info"])))]), createElement("p", {
        children: Interop_reactApi.Children.toArray(Array.from(children_7)),
    })), createElement("hr", {
        style: {
            borderTop: (((1 + "px ") + "solid") + " ") + "black",
            borderColor: "#D3D3D3",
        },
    }), dndFileUploadControl, createElement("div", createObj(ofArray([["id", weldsTable_1], ["className", join(" ", ["field"])], (elems_36 = [Bulma_control(singleton_1(weldsTable))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])]))), Bulma_field(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["control", "has-text-right"])], (elems_37 = [UI_button((inputRecord_7 = ButtonOptions_get_Default(), new ButtonOptions(openPdfModal, isEmpty(model.SelectedWelds), Strings__get_Generate_Pdf(strings), ofArray(["is-primary", "is-large"]), inputRecord_7.IconClasses, (_arg9) => {
        dispatch(new Msg(18));
    })))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])]))))), UI_modal(generatePdfModalOptions), UI_modal(tosModalOptions)]);
    formular = createElement("form", {
        children: Interop_reactApi.Children.toArray(Array.from(children_9)),
    });
    return react.createElement(react.Fragment, {}, formular, spinner);
}

