import { Union } from "./fable_modules/fable-library.3.7.11/Types.js";
import { union_type } from "./fable_modules/fable-library.3.7.11/Reflection.js";
import { singleton, tail, head, isEmpty } from "./fable_modules/fable-library.3.7.11/List.js";
import { Cmd_ofSub } from "./fable_modules/Feliz.Router.3.8.0/../Fable.Elmish.3.1.0/cmd.fs.js";
import { RouterModule_encodeParts, RouterModule_nav } from "./fable_modules/Feliz.Router.3.8.0/./Router.fs.js";

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotFound", "EasyDoc", "Imprint", "TermsOfService", "CookiePolicy"];
    }
}

export function Page$reflection() {
    return union_type("PageHelpers.Page", [], Page, () => [[], [], [], [], []]);
}

export function parseUrl(_arg1) {
    let pattern_matching_result;
    if (!isEmpty(_arg1)) {
        if (head(_arg1) === "imprint") {
            if (isEmpty(tail(_arg1))) {
                pattern_matching_result = 1;
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else if (head(_arg1) === "tos") {
            if (isEmpty(tail(_arg1))) {
                pattern_matching_result = 2;
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else if (head(_arg1) === "cookie-policy") {
            if (isEmpty(tail(_arg1))) {
                pattern_matching_result = 3;
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new Page(1);
        }
        case 1: {
            return new Page(2);
        }
        case 2: {
            return new Page(3);
        }
        case 3: {
            return new Page(4);
        }
        case 4: {
            return new Page(0);
        }
    }
}

export function navigateTo(_arg1) {
    switch (_arg1.tag) {
        case 0: {
            return Cmd_ofSub((_arg62_1) => {
                RouterModule_nav(singleton(""), 1, 1);
            });
        }
        case 2: {
            return Cmd_ofSub((_arg62_2) => {
                RouterModule_nav(singleton("imprint"), 1, 1);
            });
        }
        case 3: {
            return Cmd_ofSub((_arg62_3) => {
                RouterModule_nav(singleton("tos"), 1, 1);
            });
        }
        case 4: {
            return Cmd_ofSub((_arg62_4) => {
                RouterModule_nav(singleton("cookie-policy"), 1, 1);
            });
        }
        default: {
            return Cmd_ofSub((_arg62) => {
                RouterModule_nav(singleton(""), 1, 1);
            });
        }
    }
}

export function href(_arg1) {
    switch (_arg1.tag) {
        case 0: {
            return RouterModule_encodeParts(singleton(""), 1);
        }
        case 2: {
            return RouterModule_encodeParts(singleton("imprint"), 1);
        }
        case 3: {
            return RouterModule_encodeParts(singleton("tos"), 1);
        }
        case 4: {
            return RouterModule_encodeParts(singleton("cookie-policy"), 1);
        }
        default: {
            return RouterModule_encodeParts(singleton(""), 1);
        }
    }
}

export class Deferred$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HasNotStartedYet", "InProgress", "Resolved"];
    }
}

export function Deferred$1$reflection(gen0) {
    return union_type("PageHelpers.Deferred`1", [gen0], Deferred$1, () => [[], [], [["Item", gen0]]]);
}

export class AsyncOperationStatus$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Started", "Finished"];
    }
}

export function AsyncOperationStatus$1$reflection(gen0) {
    return union_type("PageHelpers.AsyncOperationStatus`1", [gen0], AsyncOperationStatus$1, () => [[], [["Item", gen0]]]);
}

export function Deferred_resolved(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return false;
        }
        case 2: {
            return true;
        }
        default: {
            return false;
        }
    }
}

export function Deferred_inProgress(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return true;
        }
        case 2: {
            return false;
        }
        default: {
            return false;
        }
    }
}

export function Deferred_map(transform, deferred) {
    switch (deferred.tag) {
        case 1: {
            return new Deferred$1(1);
        }
        case 2: {
            return new Deferred$1(2, transform(deferred.fields[0]));
        }
        default: {
            return new Deferred$1(0);
        }
    }
}

export function Deferred_exists(predicate, _arg1) {
    switch (_arg1.tag) {
        case 1: {
            return false;
        }
        case 2: {
            return predicate(_arg1.fields[0]);
        }
        default: {
            return false;
        }
    }
}

export function Deferred_bind(transform, deferred) {
    switch (deferred.tag) {
        case 1: {
            return new Deferred$1(1);
        }
        case 2: {
            return transform(deferred.fields[0]);
        }
        default: {
            return new Deferred$1(0);
        }
    }
}

