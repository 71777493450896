import { ProxyRequestException__get_Response, ProxyRequestException } from "./fable_modules/Fable.Remoting.Client.7.19.0/Types.fs.js";
import { ServerError$reflection, ServerError } from "../Shared/Remoting.fs.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.23.0/./SimpleJson.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.23.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.23.0/./TypeInfo.Converter.fs.js";
import { anonRecord_type } from "./fable_modules/fable-library.3.7.11/Reflection.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "./fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.11/Choice.js";

export function exnToError(e) {
    let matchValue;
    if (e instanceof ProxyRequestException) {
        try {
            return ((matchValue = SimpleJson_tryParse(ProxyRequestException__get_Response(e).ResponseBody), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(anonRecord_type(["error", ServerError$reflection()]))) : (() => {
                throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
            })())).error;
        }
        catch (matchValue_1) {
            return new ServerError(0, e.message);
        }
    }
    else {
        return new ServerError(0, e.message);
    }
}

export function Cmd_OfAsync_eitherAsResult(f, args, resultMsg) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, f, args, (arg) => resultMsg(new FSharpResult$2(0, arg)), (arg_2) => resultMsg(new FSharpResult$2(1, exnToError(arg_2))));
}

export function DomainError_explain(strings, _arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "File structure error";
        }
        case 2: {
            return "Missing signature";
        }
        case 3: {
            return `Invalid signature in line ${_arg1.fields[0]}`;
        }
        default: {
            return "File is null";
        }
    }
}

export function ServerError_explain(strings, _arg1) {
    if (_arg1.tag === 1) {
        return DomainError_explain(strings, _arg1.fields[0]);
    }
    else {
        return "Something went wrong. Sorry.";
    }
}

