import { Union, Record } from "./fable_modules/fable-library.3.7.11/Types.js";
import { unit_type, list_type, tuple_type, option_type, lambda_type, class_type, union_type, string_type, record_type, bool_type, int32_type } from "./fable_modules/fable-library.3.7.11/Reflection.js";
import { Alignment$reflection } from "../Shared/WeldingTable.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "./fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { StyleImports_useTheme } from "./fable_modules/Feliz.MaterialUI.1.2.6/./Styles.fs.js";
import { MuiHelpers_reactElement } from "./fable_modules/Feliz.MaterialUI.MaterialTable.1.11.0/../Feliz.MaterialUI.1.2.6/Mui.fs.js";
import material_table from "material-table";
import { structuralHash, equals, curry, createObj } from "./fable_modules/fable-library.3.7.11/Util.js";
import { length, empty, map, singleton, append, delay, toList } from "./fable_modules/fable-library.3.7.11/Seq.js";
import { map as map_1, ofSeq, ofArray, singleton as singleton_1 } from "./fable_modules/fable-library.3.7.11/List.js";
import { except } from "./fable_modules/fable-library.3.7.11/Seq2.js";

export class ColumnProps extends Record {
    constructor(Column, IsVisible, Alignment) {
        super();
        this.Column = (Column | 0);
        this.IsVisible = IsVisible;
        this.Alignment = Alignment;
    }
}

export function ColumnProps$reflection() {
    return record_type("Grid.ColumnProps", [], ColumnProps, () => [["Column", int32_type], ["IsVisible", bool_type], ["Alignment", Alignment$reflection()]]);
}

export class GridTranslations extends Record {
    constructor(Title, Search, NextPage, PreviousPage, FirstPage, LastPage, ShowColumns, AddOrRemoveColumns, NoRecords) {
        super();
        this.Title = Title;
        this.Search = Search;
        this.NextPage = NextPage;
        this.PreviousPage = PreviousPage;
        this.FirstPage = FirstPage;
        this.LastPage = LastPage;
        this.ShowColumns = ShowColumns;
        this.AddOrRemoveColumns = AddOrRemoveColumns;
        this.NoRecords = NoRecords;
    }
}

export function GridTranslations$reflection() {
    return record_type("Grid.GridTranslations", [], GridTranslations, () => [["Title", string_type], ["Search", string_type], ["NextPage", string_type], ["PreviousPage", string_type], ["FirstPage", string_type], ["LastPage", string_type], ["ShowColumns", string_type], ["AddOrRemoveColumns", string_type], ["NoRecords", string_type]]);
}

export class GridId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EasyDocWelds"];
    }
}

export function GridId$reflection() {
    return union_type("Grid.GridId", [], GridId, () => [[]]);
}

export function GridIdModule_toString(_arg1) {
    return "EasyDocWelds";
}

export class TableDefinition$1 extends Record {
    constructor(Identifier, ToHeader, ToContent, FormatContent, ToFieldName, ToColumnType, ToFilterOptions, ToRowId, Translations) {
        super();
        this.Identifier = Identifier;
        this.ToHeader = ToHeader;
        this.ToContent = ToContent;
        this.FormatContent = FormatContent;
        this.ToFieldName = ToFieldName;
        this.ToColumnType = ToColumnType;
        this.ToFilterOptions = ToFilterOptions;
        this.ToRowId = ToRowId;
        this.Translations = Translations;
    }
}

export function TableDefinition$1$reflection(gen0) {
    return record_type("Grid.TableDefinition`1", [gen0], TableDefinition$1, () => [["Identifier", GridId$reflection()], ["ToHeader", lambda_type(int32_type, class_type("Fable.React.ReactElement"))], ["ToContent", lambda_type(gen0, lambda_type(int32_type, string_type))], ["FormatContent", lambda_type(gen0, lambda_type(int32_type, class_type("Fable.React.ReactElement")))], ["ToFieldName", lambda_type(int32_type, string_type)], ["ToColumnType", lambda_type(int32_type, option_type(class_type("Feliz.MaterialUI.MaterialTable.IColumnProperty")))], ["ToFilterOptions", lambda_type(int32_type, option_type(list_type(tuple_type(int32_type, string_type))))], ["ToRowId", lambda_type(gen0, string_type)], ["Translations", GridTranslations$reflection()]]);
}

export class GridProps$1 extends Record {
    constructor(TableDefinition, Data, Columns, SelectionActive, SelectionChangeAction) {
        super();
        this.TableDefinition = TableDefinition;
        this.Data = Data;
        this.Columns = Columns;
        this.SelectionActive = SelectionActive;
        this.SelectionChangeAction = SelectionChangeAction;
    }
}

export function GridProps$1$reflection(gen0) {
    return record_type("Grid.GridProps`1", [gen0], GridProps$1, () => [["TableDefinition", TableDefinition$1$reflection(gen0)], ["Data", list_type(gen0)], ["Columns", list_type(ColumnProps$reflection())], ["SelectionActive", bool_type], ["SelectionChangeAction", option_type(lambda_type(list_type(gen0), lambda_type(gen0, unit_type)))]]);
}

export function UI_gridComponent() {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        const theme = StyleImports_useTheme();
        const strings = props.TableDefinition.Translations;
        const alignmentToTextAlign = (_arg1) => {
            switch (_arg1.tag) {
                case 1: {
                    return ["textAlign", "right"];
                }
                case 2: {
                    return ["textAlign", "center"];
                }
                default: {
                    return ["textAlign", "left"];
                }
            }
        };
        return MuiHelpers_reactElement(material_table, createObj(toList(delay(() => append(singleton(["style", {
            backgroundColor: theme.palette.background.default,
        }]), delay(() => append(singleton(["title", strings.Title]), delay(() => {
            let arg00;
            return append(singleton(["columns", (arg00 = toList(delay(() => map((col) => createObj(toList(delay(() => append(singleton(["title", props.TableDefinition.ToHeader(col.Column)]), delay(() => append(singleton(["hidden", !col.IsVisible]), delay(() => append(singleton(["field", props.TableDefinition.ToFieldName(col.Column)]), delay(() => append(singleton(["filtering", true]), delay(() => {
                let matchValue;
                return append((matchValue = props.TableDefinition.ToFilterOptions(col.Column), (matchValue == null) ? singleton(["customFilterAndSearch", (delegateArg0, delegateArg1, delegateArg2) => {
                    const content = props.TableDefinition.ToContent(delegateArg1, col.Column);
                    return content.toLocaleLowerCase().indexOf(delegateArg0.toLocaleLowerCase()) >= 0;
                }]) : singleton(["lookup", createObj(matchValue)])), delay(() => append(singleton(["cellStyle", createObj(singleton_1(alignmentToTextAlign(col.Alignment)))]), delay(() => append(singleton(["headerStyle", createObj(ofArray([alignmentToTextAlign(col.Alignment), ["verticalAlign", "top"]]))]), delay(() => {
                    let matchValue_1;
                    return append((matchValue_1 = props.TableDefinition.ToColumnType(col.Column), (matchValue_1 == null) ? ((empty())) : singleton(matchValue_1)), delay(() => singleton(["render", (delegateArg0_1, delegateArg1_1) => props.TableDefinition.FormatContent(delegateArg0_1, col.Column)])));
                }))))));
            }))))))))))), props.Columns))), Array.from(arg00))]), delay(() => append(singleton(["data", Array.from(props.Data)]), delay(() => append(singleton(["localization", {
                toolbar: {
                    searchPlaceholder: strings.Search,
                    searchTooltip: strings.Search,
                    addRemoveColumns: strings.AddOrRemoveColumns,
                    showColumnsTitle: strings.ShowColumns,
                    showColumnsAriaLabel: strings.ShowColumns,
                },
                pagination: {
                    nextTooltip: strings.NextPage,
                    previousTooltip: strings.PreviousPage,
                    firstTooltip: strings.FirstPage,
                    lastTooltip: strings.LastPage,
                },
                body: {
                    emptyDataSourceMessage: strings.NoRecords,
                },
            }]), delay(() => append(singleton(["options", {
                columnsButton: true,
                headerStyle: {
                    fontWeight: "bold",
                },
                actionsColumnIndex: -1,
                selection: props.SelectionActive,
                pageSize: 25,
                pageSizeOptions: [],
                filtering: true,
            }]), delay(() => {
                const matchValue_2 = props.SelectionChangeAction;
                if (curry(2, matchValue_2) != null) {
                    const osc = curry(2, matchValue_2);
                    return singleton(["onSelectionChange", (xs, x) => {
                        osc(ofSeq(xs))(x);
                    }]);
                }
                else {
                    return empty();
                }
            }))))))));
        }))))))));
    }, void 0, (oldProps, newProps) => {
        let a;
        const arg10 = map_1(newProps.TableDefinition.ToRowId, newProps.Data);
        return ((a = map_1(oldProps.TableDefinition.ToRowId, oldProps.Data), (b) => (((length(a) === length(b)) && (length(except(a, b, {
            Equals: equals,
            GetHashCode: structuralHash,
        })) === 0)) && (length(except(b, a, {
            Equals: equals,
            GetHashCode: structuralHash,
        })) === 0))))(arg10);
    }, void 0, "gridComponent", "/build/src/Client/Grid.fs", 58);
}

