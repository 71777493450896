import { printf, format, interpolate, toText } from "../Client/fable_modules/fable-library.3.7.11/String.js";
import { milliseconds, fromSeconds, totalHours, days, hours, minutes, totalDays, totalSeconds, seconds, totalMinutes } from "../Client/fable_modules/fable-library.3.7.11/TimeSpan.js";

export function formatFloat(value) {
    return toText(interpolate("%.1f%P()", [value]));
}

export function formatMinSec(ts) {
    if (totalMinutes(ts) >= 1) {
        const mins = (~(~totalMinutes(ts))) | 0;
        let arg20;
        let copyOfStruct = seconds(ts);
        arg20 = format('{0:' + "00" + '}', copyOfStruct);
        const arg10 = format('{0:' + "00" + '}', mins);
        return toText(printf("%s:%s min"))(arg10)(arg20);
    }
    else {
        const arg10_1 = totalSeconds(ts);
        return toText(printf("%.1f s"))(arg10_1);
    }
}

export function formatDuration(x) {
    if (totalDays(x) >= 1) {
        const arg30 = minutes(x) | 0;
        const arg20 = hours(x) | 0;
        const arg10 = days(x) | 0;
        return toText(printf("%d d %d h %d min"))(arg10)(arg20)(arg30);
    }
    else if (totalHours(x) >= 1) {
        const arg20_1 = minutes(x) | 0;
        const arg10_1 = hours(x) | 0;
        return toText(printf("%d h %d min"))(arg10_1)(arg20_1);
    }
    else if (totalMinutes(x) >= 1) {
        const arg20_2 = seconds(x) | 0;
        const arg10_2 = minutes(x) | 0;
        return toText(printf("%d min %d s"))(arg10_2)(arg20_2);
    }
    else {
        let arg10_3;
        let copyOfStruct = fromSeconds(seconds(x) + (milliseconds(x) / 1000));
        arg10_3 = totalSeconds(copyOfStruct);
        return toText(printf("%0.1f s"))(arg10_3);
    }
}

