import { replace, printf, toText } from "../Client/fable_modules/fable-library.3.7.11/String.js";
import { FSharpException, FSharpRef, Union, Record } from "../Client/fable_modules/fable-library.3.7.11/Types.js";
import { array_type, uint8_type, tuple_type, bool_type, list_type, enum_type, float64_type, int32_type, union_type, record_type, option_type, lambda_type, class_type, string_type, unit_type } from "../Client/fable_modules/fable-library.3.7.11/Reflection.js";
import { ofArray } from "../Client/fable_modules/fable-library.3.7.11/List.js";
import { tryParse } from "../Client/fable_modules/fable-library.3.7.11/Int32.js";
import { equals } from "../Client/fable_modules/fable-library.3.7.11/Util.js";

export function Route_server(typeName, methodName) {
    return toText(printf("/%s"))(methodName);
}

export function Route_client(basePath, typeName, methodName) {
    return toText(printf("%s/%s/%s"))(basePath)(typeName)(methodName);
}

export class IPublicApi extends Record {
    constructor(getVersion, getUser) {
        super();
        this.getVersion = getVersion;
        this.getUser = getUser;
    }
}

export function IPublicApi$reflection() {
    return record_type("Remoting.IPublicApi", [], IPublicApi, () => [["getVersion", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [string_type]))], ["getUser", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [option_type(string_type)]))]]);
}

export class SignatureType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Official", "EmulatorFixed", "EmulatorDerived"];
    }
}

export function SignatureType$reflection() {
    return union_type("Remoting.SignatureType", [], SignatureType, () => [[], [], []]);
}

export class EasyDocuMetadata extends Record {
    constructor(Machine, FileName, ArticleNumber, SerialNumber, FirmwarePowersource, FirmwareDocMag, SignatureType) {
        super();
        this.Machine = Machine;
        this.FileName = FileName;
        this.ArticleNumber = ArticleNumber;
        this.SerialNumber = SerialNumber;
        this.FirmwarePowersource = FirmwarePowersource;
        this.FirmwareDocMag = FirmwareDocMag;
        this.SignatureType = SignatureType;
    }
}

export function EasyDocuMetadata$reflection() {
    return record_type("Remoting.EasyDocuMetadata", [], EasyDocuMetadata, () => [["Machine", string_type], ["FileName", string_type], ["ArticleNumber", string_type], ["SerialNumber", string_type], ["FirmwarePowersource", string_type], ["FirmwareDocMag", string_type], ["SignatureType", SignatureType$reflection()]]);
}

export function EasyDocuMetadata_get_Empty() {
    return new EasyDocuMetadata("", "", "", "", "", "", new SignatureType(0));
}

export const WeldingProcess_all = ofArray([0, 1, 2, 3, 4, 5, 6, 7]);

export function WeldingProcess_parse(_arg1) {
    switch (_arg1) {
        case "GMAW_MAN": {
            return 0;
        }
        case "GMAW_STD": {
            return 1;
        }
        case "GMAW_PULSE": {
            return 2;
        }
        case "GMAW_STD_S": {
            return 3;
        }
        case "GMAW_PULSE_S": {
            return 4;
        }
        case "STICK": {
            return 5;
        }
        case "TIG": {
            return 6;
        }
        case "TIG_PULSE": {
            return 7;
        }
        default: {
            throw (new Error("unknown value for WeldingProcess"));
        }
    }
}

export function WeldingProcess_toString(_arg1) {
    switch (_arg1) {
        case 0: {
            return "GMAW_MAN";
        }
        case 1: {
            return "GMAW_STD";
        }
        case 2: {
            return "GMAW_PULSE";
        }
        case 3: {
            return "GMAW_STD_S";
        }
        case 4: {
            return "GMAW_PULSE_S";
        }
        case 5: {
            return "STICK";
        }
        case 6: {
            return "TIG";
        }
        case 7: {
            return "TIG_PULSE";
        }
        default: {
            throw (new Error("unknown value for WeldingProcess"));
        }
    }
}

export const WeldingMode_all = ofArray([0, 2, 1, 3, 4]);

export function WeldingMode_tryParse(_arg1) {
    switch (_arg1) {
        case "2T": {
            return 0;
        }
        case "S2T": {
            return 1;
        }
        case "4T": {
            return 2;
        }
        case "S4T": {
            return 3;
        }
        case "TAC":
        case "SPOT": {
            return 4;
        }
        default: {
            return void 0;
        }
    }
}

export function WeldingMode_toString(_arg1) {
    switch (_arg1) {
        case 0: {
            return "2T";
        }
        case 1: {
            return "S2T";
        }
        case 2: {
            return "4T";
        }
        case 3: {
            return "S4T";
        }
        case 4: {
            return "SPOT";
        }
        default: {
            throw (new Error("unknown value for WeldingMode"));
        }
    }
}

export const WeldingStatus_all = ofArray([0, 1, 2]);

export function WeldingStatus_parse(_arg1) {
    let matchValue, outArg;
    let pattern_matching_result;
    if (_arg1 === "PASSED") {
        pattern_matching_result = 0;
    }
    else if (_arg1 === "COMPLETED") {
        pattern_matching_result = 0;
    }
    else if (_arg1 === "IGN") {
        pattern_matching_result = 1;
    }
    else if (_arg1 === "IGNITION") {
        pattern_matching_result = 1;
    }
    else if (_arg1.indexOf("Err") === 0) {
        pattern_matching_result = 2;
    }
    else {
        pattern_matching_result = 3;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [0, void 0];
        }
        case 1: {
            return [1, void 0];
        }
        case 2: {
            return [2, (matchValue = ((outArg = 0, [tryParse(replace(_arg1, "Err", "").trim(), 511, false, 32, new FSharpRef(() => outArg, (v) => {
                outArg = (v | 0);
            })), outArg])), matchValue[0] ? matchValue[1] : -1)];
        }
        case 3: {
            return [2, -1];
        }
    }
}

export function WeldingStatus_toString(_arg1) {
    switch (_arg1) {
        case 0: {
            return "COMPLETED";
        }
        case 1: {
            return "IGNITION";
        }
        case 2: {
            return "ERROR";
        }
        default: {
            throw (new Error(`Invalid WeldingStatus ${_arg1}`));
        }
    }
}

export class EasyDocuWeld extends Record {
    constructor(SeamNumber, Date$, Duration, Current, Voltage, WireFeedMetric, WireFeedImperial, Power, Energy, MotorCurrent, SynergicLineId, EasyJob, Process, Mode, Status, StatusCode, Interval) {
        super();
        this.SeamNumber = (SeamNumber | 0);
        this.Date = Date$;
        this.Duration = Duration;
        this.Current = Current;
        this.Voltage = Voltage;
        this.WireFeedMetric = WireFeedMetric;
        this.WireFeedImperial = WireFeedImperial;
        this.Power = Power;
        this.Energy = Energy;
        this.MotorCurrent = MotorCurrent;
        this.SynergicLineId = (SynergicLineId | 0);
        this.EasyJob = EasyJob;
        this.Process = (Process | 0);
        this.Mode = Mode;
        this.Status = (Status | 0);
        this.StatusCode = StatusCode;
        this.Interval = Interval;
    }
}

export function EasyDocuWeld$reflection() {
    return record_type("Remoting.EasyDocuWeld", [], EasyDocuWeld, () => [["SeamNumber", int32_type], ["Date", class_type("System.DateTime")], ["Duration", class_type("System.TimeSpan")], ["Current", float64_type], ["Voltage", float64_type], ["WireFeedMetric", float64_type], ["WireFeedImperial", float64_type], ["Power", float64_type], ["Energy", float64_type], ["MotorCurrent", float64_type], ["SynergicLineId", int32_type], ["EasyJob", option_type(int32_type)], ["Process", enum_type("Remoting.WeldingProcess.WeldingProcess", int32_type, [["GMAW_MAN", 0], ["GMAW_STD", 1], ["GMAW_PULSE", 2], ["GMAW_STD_S", 3], ["GMAW_PULSE_S", 4], ["STICK", 5], ["TIG", 6], ["TIG_PULSE", 7]])], ["Mode", option_type(enum_type("Remoting.WeldingMode.WeldingMode", int32_type, [["Mode_2T", 0], ["Mode_S2T", 1], ["Mode_4T", 2], ["Mode_S4T", 3], ["Mode_SPOT", 4]]))], ["Status", enum_type("Remoting.WeldingStatus.WeldingStatus", int32_type, [["Completed", 0], ["Ign", 1], ["Err", 2]])], ["StatusCode", option_type(int32_type)], ["Interval", option_type(int32_type)]]);
}

export class EasyDocuFile extends Record {
    constructor(Metadata, Welds) {
        super();
        this.Metadata = Metadata;
        this.Welds = Welds;
    }
}

export function EasyDocuFile$reflection() {
    return record_type("Remoting.EasyDocuFile", [], EasyDocuFile, () => [["Metadata", EasyDocuMetadata$reflection()], ["Welds", list_type(EasyDocuWeld$reflection())]]);
}

export class MeasurementUnits extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Metric", "Imperial"];
    }
}

export function MeasurementUnits$reflection() {
    return union_type("Remoting.MeasurementUnits", [], MeasurementUnits, () => [[], []]);
}

export function MeasurementUnits_get_ToString() {
    return (_arg1) => ((_arg1.tag === 1) ? "Imperial" : "Metric");
}

export function MeasurementUnits_get_OfString() {
    return (_arg2) => ((_arg2 === "Imperial") ? (new MeasurementUnits(1)) : (new MeasurementUnits(0)));
}

export class EasyDocuHeaderData extends Record {
    constructor(CustomerName, CustomerNumber, OrderNumber, Remarks, Component, Company, Welder) {
        super();
        this.CustomerName = CustomerName;
        this.CustomerNumber = CustomerNumber;
        this.OrderNumber = OrderNumber;
        this.Remarks = Remarks;
        this.Component = Component;
        this.Company = Company;
        this.Welder = Welder;
    }
}

export function EasyDocuHeaderData$reflection() {
    return record_type("Remoting.EasyDocuHeaderData", [], EasyDocuHeaderData, () => [["CustomerName", string_type], ["CustomerNumber", string_type], ["OrderNumber", string_type], ["Remarks", string_type], ["Component", string_type], ["Company", string_type], ["Welder", string_type]]);
}

export function EasyDocuHeaderData_get_Empty() {
    return new EasyDocuHeaderData("", "", "", "", "", "", "");
}

export class Language extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["German", "English"];
    }
}

export function Language$reflection() {
    return union_type("Remoting.Language", [], Language, () => [[], []]);
}

export function Language__get_Code(this$) {
    if (this$.tag === 1) {
        return "en";
    }
    else {
        return "de";
    }
}

export class EasyDocuOptions extends Record {
    constructor(Language, MeasurementUnits, ShowFroniusLogo, ShowSignatureLine) {
        super();
        this.Language = Language;
        this.MeasurementUnits = MeasurementUnits;
        this.ShowFroniusLogo = ShowFroniusLogo;
        this.ShowSignatureLine = ShowSignatureLine;
    }
}

export function EasyDocuOptions$reflection() {
    return record_type("Remoting.EasyDocuOptions", [], EasyDocuOptions, () => [["Language", Language$reflection()], ["MeasurementUnits", MeasurementUnits$reflection()], ["ShowFroniusLogo", bool_type], ["ShowSignatureLine", bool_type]]);
}

export function EasyDocuOptions_get_Default() {
    return new EasyDocuOptions(new Language(0), new MeasurementUnits(0), true, true);
}

export class GeneratePdfCommand extends Record {
    constructor(Options, HeaderData, Files, SelectedWelds) {
        super();
        this.Options = Options;
        this.HeaderData = HeaderData;
        this.Files = Files;
        this.SelectedWelds = SelectedWelds;
    }
}

export function GeneratePdfCommand$reflection() {
    return record_type("Remoting.GeneratePdfCommand", [], GeneratePdfCommand, () => [["Options", EasyDocuOptions$reflection()], ["HeaderData", EasyDocuHeaderData$reflection()], ["Files", list_type(string_type)], ["SelectedWelds", list_type(tuple_type(int32_type, int32_type))]]);
}

export class IEasyDocApi extends Record {
    constructor(validateCsv, generatePdf) {
        super();
        this.validateCsv = validateCsv;
        this.generatePdf = generatePdf;
    }
}

export function IEasyDocApi$reflection() {
    return record_type("Remoting.IEasyDocApi", [], IEasyDocApi, () => [["validateCsv", lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [EasyDocuFile$reflection()]))], ["generatePdf", lambda_type(GeneratePdfCommand$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [array_type(uint8_type)]))]]);
}

export class DomainError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FileIsNull", "FileStructureError", "MissingSignature", "InvalidSignature"];
    }
}

export function DomainError$reflection() {
    return union_type("Remoting.DomainError", [], DomainError, () => [[], [], [], [["lineNumber", int32_type]]]);
}

export class ServerError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Exception", "Domain"];
    }
}

export function ServerError$reflection() {
    return union_type("Remoting.ServerError", [], ServerError, () => [[["Item", string_type]], [["Item", DomainError$reflection()]]]);
}

export class ServerException extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function ServerException$reflection() {
    return class_type("Remoting.ServerException", void 0, ServerException, class_type("System.Exception"));
}

function ServerException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof ServerException) {
                return equals(this$.Data0, obj.Data0);
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function ServerErrorModule_failwith(er) {
    throw (new ServerException(er));
}

export function ServerErrorModule_ofResult(v) {
    if (v.tag === 1) {
        return ServerErrorModule_failwith(v.fields[0]);
    }
    else {
        return v.fields[0];
    }
}

