import { class_type } from "./fable_modules/fable-library.3.7.11/Reflection.js";
import { printf, toConsole } from "./fable_modules/fable-library.3.7.11/String.js";
import { singleton } from "./fable_modules/fable-library.3.7.11/List.js";

export class GA {
    constructor() {
    }
}

export function GA$reflection() {
    return class_type("TagManager.GA", void 0, GA);
}

export function GA_SendEvent_Z71ADD019(category, action, label, value) {
    return singleton((_dispatch) => {
        if ("ga" in window) {
            (window.ga.getAll()[0]).send("event", category, action, label, value);
        }
        else {
            toConsole(printf("GA event: category=%s; action=%s; label=%A; value=%A"))(category)(action)(label)(value);
        }
    });
}

