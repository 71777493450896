import { Record, Union } from "./fable_modules/fable-library.3.7.11/Types.js";
import { view as view_1, update as update_1, init as init_1, Msg$reflection as Msg$reflection_1, Model$reflection as Model$reflection_1 } from "./EasyDocu.fs.js";
import { record_type, option_type, string_type, bool_type, union_type } from "./fable_modules/fable-library.3.7.11/Reflection.js";
import { Page, navigateTo, AsyncOperationStatus$1, parseUrl, AsyncOperationStatus$1$reflection, Page$reflection } from "./PageHelpers.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder, Remoting_buildProxy_Z15584635 } from "./fable_modules/Fable.Remoting.Client.7.19.0/Remoting.fs.js";
import { Language__get_Code, IPublicApi$reflection, Route_client } from "../Shared/Remoting.fs.js";
import { Cmd_batch, Cmd_none, Cmd_map } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { RouterModule_router, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.3.8.0/./Router.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "./fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { singleton, ofArray } from "./fable_modules/fable-library.3.7.11/List.js";
import { join, toText, printf, toConsole } from "./fable_modules/fable-library.3.7.11/String.js";
import { Strings__get_DataPrivacy_Link, Strings__get_DataPrivacyURL, Strings__get_CookiePolicy_Link, Strings__get_TermsOfService_Link, Strings__get_Imprint_Link, Strings__get_Contact_Link, Strings__get_LandingPage_AsteriskText, Strings__get_LandingPage_Text, Strings__get_PleaseLogin, Strings__get_Login, Strings__get_Logout, Strings_$ctor_Z721C83C5 } from "../Translations/Translations.fs.js";
import { DropdownOptions, UI_dropdown, IconOptions, IconOptions_get_Default, UI_icon, Utils_browserLanguage } from "./UIHelpers.fs.js";
import { CookieDeclaration, TermsOfService_view, Imprint_view } from "./StaticPages.fs.js";
import { createElement } from "react";
import * as react from "react";
import { collect, empty, singleton as singleton_1, append, map, delay, toList } from "./fable_modules/fable-library.3.7.11/Seq.js";
import { partialApply, createObj } from "./fable_modules/fable-library.3.7.11/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.64.0/./Interop.fs.js";
import { rangeDouble } from "./fable_modules/fable-library.3.7.11/Range.js";
import { dataPolicyLink, cookiePolicyLink, tosLink, imprintLink, loginLink as loginLink_1, logoAndName } from "../Shared/HtmlIds.fs.js";
import { authEnabled } from "../Shared/Settings.fs.js";

export class PageModel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EasyDoc", "Imprint", "TermsOfService", "CookiePolicy"];
    }
}

export function PageModel$reflection() {
    return union_type("Index.PageModel", [], PageModel, () => [[["Item", Model$reflection_1()]], [], [], []]);
}

export class Model extends Record {
    constructor(CurrentPage, PageModel, MobileMenuOpen, Version, User) {
        super();
        this.CurrentPage = CurrentPage;
        this.PageModel = PageModel;
        this.MobileMenuOpen = MobileMenuOpen;
        this.Version = Version;
        this.User = User;
    }
}

export function Model$reflection() {
    return record_type("Index.Model", [], Model, () => [["CurrentPage", Page$reflection()], ["PageModel", PageModel$reflection()], ["MobileMenuOpen", bool_type], ["Version", string_type], ["User", AsyncOperationStatus$1$reflection(option_type(string_type))]]);
}

export class MenuItem extends Record {
    constructor(Title, Href, Icon, Page) {
        super();
        this.Title = Title;
        this.Href = Href;
        this.Icon = Icon;
        this.Page = Page;
    }
}

export function MenuItem$reflection() {
    return record_type("Index.MenuItem", [], MenuItem, () => [["Title", string_type], ["Href", string_type], ["Icon", string_type], ["Page", option_type(Page$reflection())]]);
}

export const publicApi = Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_client("/api", typeName, methodName), RemotingModule_createApi()), {
    ResolveType: IPublicApi$reflection,
});

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NavigateTo", "PageChanged", "EasyDocMsg", "ToggleMobileMenu", "GotVersion", "GotUser"];
    }
}

export function Msg$reflection() {
    return union_type("Index.Msg", [], Msg, () => [[["Item", Page$reflection()]], [["Item", Page$reflection()]], [["Item", Msg$reflection_1()]], [], [["Item", string_type]], [["Item", option_type(string_type)]]]);
}

export function switchToPage(page) {
    switch (page.tag) {
        case 1: {
            const patternInput = init_1();
            return [new PageModel(0, patternInput[0]), Cmd_map((arg0) => (new Msg(2, arg0)), patternInput[1])];
        }
        case 2: {
            return [new PageModel(1), Cmd_none()];
        }
        case 3: {
            return [new PageModel(2), Cmd_none()];
        }
        case 4: {
            return [new PageModel(3), Cmd_none()];
        }
        default: {
            const patternInput = init_1();
            return [new PageModel(0, patternInput[0]), Cmd_map((arg0) => (new Msg(2, arg0)), patternInput[1])];
        }
    }
}

export function init() {
    const currentPage = parseUrl(RouterModule_urlSegments(window.location.hash, 1));
    const patternInput = switchToPage(currentPage);
    return [new Model(currentPage, patternInput[0], false, "", new AsyncOperationStatus$1(0)), Cmd_batch(ofArray([patternInput[1], Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, publicApi.getUser, void 0, (arg0) => (new Msg(5, arg0)), (_arg1) => (new Msg(5, void 0))), Cmd_OfAsyncWith_either((x_1) => {
        Cmd_OfAsync_start(x_1);
    }, publicApi.getVersion, void 0, (arg0_1) => (new Msg(4, arg0_1)), (_arg2) => (new Msg(4, "?")))]))];
}

export function update(msg, model) {
    const matchValue = [model.PageModel, msg];
    let pattern_matching_result, m, msg_1, page_1, page_2, v, u;
    if (matchValue[0].tag === 1) {
        if (matchValue[1].tag === 1) {
            pattern_matching_result = 4;
            page_1 = matchValue[1].fields[0];
        }
        else if (matchValue[1].tag === 0) {
            pattern_matching_result = 5;
            page_2 = matchValue[1].fields[0];
        }
        else if (matchValue[1].tag === 3) {
            pattern_matching_result = 6;
        }
        else if (matchValue[1].tag === 4) {
            pattern_matching_result = 7;
            v = matchValue[1].fields[0];
        }
        else if (matchValue[1].tag === 5) {
            pattern_matching_result = 8;
            u = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 1) {
            pattern_matching_result = 4;
            page_1 = matchValue[1].fields[0];
        }
        else if (matchValue[1].tag === 0) {
            pattern_matching_result = 5;
            page_2 = matchValue[1].fields[0];
        }
        else if (matchValue[1].tag === 3) {
            pattern_matching_result = 6;
        }
        else if (matchValue[1].tag === 4) {
            pattern_matching_result = 7;
            v = matchValue[1].fields[0];
        }
        else if (matchValue[1].tag === 5) {
            pattern_matching_result = 8;
            u = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[1].tag === 1) {
            pattern_matching_result = 4;
            page_1 = matchValue[1].fields[0];
        }
        else if (matchValue[1].tag === 0) {
            pattern_matching_result = 5;
            page_2 = matchValue[1].fields[0];
        }
        else if (matchValue[1].tag === 3) {
            pattern_matching_result = 6;
        }
        else if (matchValue[1].tag === 4) {
            pattern_matching_result = 7;
            v = matchValue[1].fields[0];
        }
        else if (matchValue[1].tag === 5) {
            pattern_matching_result = 8;
            u = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (matchValue[1].tag === 1) {
        pattern_matching_result = 4;
        page_1 = matchValue[1].fields[0];
    }
    else if (matchValue[1].tag === 0) {
        pattern_matching_result = 5;
        page_2 = matchValue[1].fields[0];
    }
    else if (matchValue[1].tag === 3) {
        pattern_matching_result = 6;
    }
    else if (matchValue[1].tag === 4) {
        pattern_matching_result = 7;
        v = matchValue[1].fields[0];
    }
    else if (matchValue[1].tag === 5) {
        pattern_matching_result = 8;
        u = matchValue[1].fields[0];
    }
    else {
        pattern_matching_result = 0;
        m = matchValue[0].fields[0];
        msg_1 = matchValue[1].fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            const tupledArg = update_1(msg_1, m);
            return [new Model(model.CurrentPage, ((arg0) => (new PageModel(0, arg0)))(tupledArg[0]), model.MobileMenuOpen, model.Version, model.User), Cmd_map((arg0_1) => (new Msg(2, arg0_1)), tupledArg[1])];
        }
        case 1: {
            toConsole(printf("Invalid combination!"));
            return [model, Cmd_none()];
        }
        case 2: {
            toConsole(printf("Invalid combination!"));
            return [model, Cmd_none()];
        }
        case 3: {
            toConsole(printf("Invalid combination!"));
            return [model, Cmd_none()];
        }
        case 4: {
            const page = page_1;
            const patternInput = switchToPage(page);
            return [new Model(page, patternInput[0], model.MobileMenuOpen, model.Version, model.User), patternInput[1]];
        }
        case 5: {
            return [model, navigateTo(page_2)];
        }
        case 6: {
            return [new Model(model.CurrentPage, model.PageModel, !model.MobileMenuOpen, model.Version, model.User), Cmd_none()];
        }
        case 7: {
            return [new Model(model.CurrentPage, model.PageModel, model.MobileMenuOpen, v, model.User), Cmd_none()];
        }
        case 8: {
            return [new Model(model.CurrentPage, model.PageModel, model.MobileMenuOpen, model.Version, new AsyncOperationStatus$1(1, u)), Cmd_none()];
        }
    }
}

export function view(model, dispatch) {
    let arg00, a, u, elems_3, elems_1, elems_4, elems_6, elems_5, elems_9, elems_8, elems_7, elems_10, elems_12, content, elems_19, elems_14, elems_15, elems_16, elems_17, elems_18, elements;
    const strings = Strings_$ctor_Z721C83C5(Language__get_Code(Utils_browserLanguage));
    let menuItems;
    const userMenuItemsAuthenticated = singleton(new MenuItem(Strings__get_Logout(strings), "/logout", toText(printf("%s %s"))("fa")("fa-sign-out-alt"), void 0));
    const userMenuItemsUnauthenticated = singleton(new MenuItem(Strings__get_Login(strings), "/login", toText(printf("%s %s"))("fa")("fa-sign-in-alt"), void 0));
    const matchValue = model.User;
    let pattern_matching_result;
    if (matchValue.tag === 1) {
        if (matchValue.fields[0] != null) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            menuItems = userMenuItemsAuthenticated;
            break;
        }
        case 1: {
            menuItems = userMenuItemsUnauthenticated;
            break;
        }
    }
    let pageHtml;
    const matchValue_1 = model.PageModel;
    switch (matchValue_1.tag) {
        case 1: {
            pageHtml = Imprint_view(strings);
            break;
        }
        case 2: {
            pageHtml = TermsOfService_view(strings);
            break;
        }
        case 3: {
            pageHtml = createElement(CookieDeclaration, {
                strings: strings,
            });
            break;
        }
        default: {
            pageHtml = view_1(strings, matchValue_1.fields[0], (arg) => {
                dispatch(new Msg(2, arg));
            });
        }
    }
    let userDropdown;
    const userIcon = UI_icon(new IconOptions(IconOptions_get_Default().Title, ofArray(["fa", "fa-user"])));
    userDropdown = createElement(UI_dropdown, new DropdownOptions("user-dropdown-menu", (arg00 = ofArray([userIcon, createElement("span", {
        children: (a = model.User, (a.tag === 0) ? "" : ((a.fields[0] != null) ? ((u = a.fields[0], u)) : "Anonymous")),
        className: "item-text",
    })]), react.createElement(react.Fragment, {}, ...arg00)), singleton("is-right"), userIcon, true, toList(delay(() => map((item) => {
        let arg00_1;
        return [(arg00_1 = ofArray([UI_icon(new IconOptions(IconOptions_get_Default().Title, singleton(item.Icon))), createElement("a", {
            href: item.Href,
            children: item.Title,
            className: join(" ", ["item-text"]),
        })]), react.createElement(react.Fragment, {}, ...arg00_1)), item.Href];
    }, menuItems)))));
    const brandAndVersion = (isMobile) => {
        let elems;
        return createElement("div", createObj(ofArray([["id", "brand-and-version"], ["className", join(" ", toList(delay(() => append((!isMobile) ? singleton_1("level-item") : empty(), delay(() => singleton_1("has-text-right-mobile"))))))], ["onClick", (_arg1) => {
            dispatch(new Msg(0, new Page(1)));
        }], (elems = [createElement("img", {
            id: "header-logo",
            src: "/Fronius_logo_SVG.svg",
            alt: "Logo",
        }), createElement("span", {
            id: "header-product-name",
            children: "Easy Documentation",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    };
    const navbarMenu = createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("navbar-menu"), delay(() => (model.MobileMenuOpen ? singleton_1("is-active") : empty()))))))], (elems_3 = [createElement("div", createObj(ofArray([["className", "navbar-start"], (elems_1 = toList(delay(() => map((mi) => createElement("a", {
        className: join(" ", ["navbar-item", "is-hidden-tablet"]),
        children: mi.Title,
        onClick: (_arg2) => {
            dispatch(new Msg(3));
        },
        href: mi.Href,
    }), menuItems))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", {
        className: "navbar-end",
        children: Interop_reactApi.Children.toArray([]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
    const mobileMenuBurger = createElement("a", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("navbar-burger"), delay(() => append(singleton_1("is-hidden-tablet"), delay(() => (model.MobileMenuOpen ? singleton_1("is-active") : empty()))))))))], ["onClick", (_arg3) => {
        dispatch(new Msg(3));
    }], (elems_4 = toList(delay(() => collect((matchValue_2) => singleton_1(createElement("span", {
        ["aria-hidden"]: true,
    })), toList(rangeDouble(1, 1, 3))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
    const navbar = createElement("div", createObj(ofArray([["className", join(" ", ["navbar", "is-hidden-tablet"])], (elems_6 = [createElement("div", createObj(ofArray([["className", "navbar-brand"], (elems_5 = [brandAndVersion(true), mobileMenuBurger], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), navbarMenu], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
    const header = createElement("div", createObj(ofArray([["className", join(" ", ["header-top red-border"])], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", ["level", "is-hidden-mobile"])], (elems_8 = [createElement("div", createObj(ofArray([["onClick", (_arg4) => {
        dispatch(new Msg(0, new Page(1)));
    }], ["id", logoAndName], ["className", join(" ", ["level-left"])], (elems_7 = [brandAndVersion(false)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", {
        className: join(" ", ["level-right", "is-hidden-mobile"]),
        id: "header-user",
        children: userDropdown,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), navbar], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])));
    const mainContent = createElement("section", {
        key: "main-content",
        className: join(" ", ["main-content", "section"]),
        children: pageHtml,
    });
    let anonymousContent;
    const loginLink = createElement("a", createObj(ofArray([["id", loginLink_1], ["href", "/login"], ["className", join(" ", ["button", "is-primary", "is-large"])], (elems_10 = [createElement("h2", {
        children: Strings__get_PleaseLogin(strings),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])));
    const divWithClasses = (classes, children_2) => createElement("div", {
        className: join(" ", classes),
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
    let heroImage;
    const arg10_2 = singleton(createElement("img", {
        id: "hero-logo",
        src: "/pw-ms-easydocu-hero.jpg",
    }));
    heroImage = divWithClasses(["img-wrapper"], arg10_2);
    const heroHeadline = createElement("h1", createObj(ofArray([["className", join(" ", ["hero-headline"])], (elems_12 = [createElement("span", {
        className: join(" ", ["headline-red"]),
        children: "Easy Documentation",
    }), createElement("span", {
        className: join(" ", ["headline-device"]),
        children: "Transsteel",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])));
    const imageLeft = createElement("img", {
        src: "/pw-ms-easydocu-TSt-left.jpg",
    });
    let columns;
    const clo1_3 = partialApply(1, divWithClasses, [["columns"]]);
    columns = (clo1_3);
    let halfColumn;
    const clo1_4 = partialApply(1, divWithClasses, [["column", "is-half"]]);
    halfColumn = (clo1_4);
    let fullColumn;
    const clo1_5 = partialApply(1, divWithClasses, [["column", "is-full"]]);
    fullColumn = (clo1_5);
    const textRight = ofArray([columns(singleton(fullColumn(singleton(createElement("div", createObj(singleton((content = Strings__get_LandingPage_Text(strings), ["dangerouslySetInnerHTML", {
        __html: content,
    }])))))))), columns(singleton(fullColumn(singleton(loginLink))))]);
    const middleArea = columns(ofArray([halfColumn(singleton(imageLeft)), halfColumn(textRight)]));
    const asteriskText = columns(singleton(fullColumn(singleton(Strings__get_LandingPage_AsteriskText(strings)))));
    const arg10_6 = ofArray([heroImage, heroHeadline, createElement("section", {
        className: join(" ", ["section"]),
        children: Interop_reactApi.Children.toArray([middleArea, asteriskText]),
    })]);
    anonymousContent = divWithClasses(["landingpage-content"], arg10_6);
    const footer = createElement("div", createObj(ofArray([["className", join(" ", ["footer-bottom", "has-text-centered"])], (elems_19 = [createElement("a", createObj(ofArray([["href", "https://www.fronius.com/en/welding-technology/contact/find-contacts-worldwide"], (elems_14 = [Strings__get_Contact_Link(strings)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("a", createObj(ofArray([["id", imprintLink], ["onClick", (_arg5) => {
        dispatch(new Msg(0, new Page(2)));
    }], (elems_15 = [Strings__get_Imprint_Link(strings)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))), createElement("a", createObj(ofArray([["id", tosLink], ["onClick", (_arg6) => {
        dispatch(new Msg(0, new Page(3)));
    }], (elems_16 = [Strings__get_TermsOfService_Link(strings)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]))), createElement("a", createObj(ofArray([["id", cookiePolicyLink], ["onClick", (_arg7) => {
        dispatch(new Msg(0, new Page(4)));
    }], (elems_17 = [Strings__get_CookiePolicy_Link(strings)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))), createElement("a", createObj(ofArray([["id", dataPolicyLink], ["href", Strings__get_DataPrivacyURL(strings)], (elems_18 = [Strings__get_DataPrivacy_Link(strings)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])])));
    return RouterModule_router(createObj(ofArray([["onUrlChanged", (arg_2) => {
        dispatch(new Msg(1, parseUrl(arg_2)));
    }], (elements = toList(delay(() => append(singleton_1(header), delay(() => {
        let matchValue_3, matchValue_4;
        return append((matchValue_3 = [authEnabled, model.User], matchValue_3[0] ? ((matchValue_3[1].tag === 0) ? singleton_1(null) : ((matchValue_3[1].fields[0] != null) ? singleton_1(mainContent) : ((matchValue_4 = model.CurrentPage, (matchValue_4.tag === 2) ? singleton_1(mainContent) : ((matchValue_4.tag === 3) ? singleton_1(mainContent) : ((matchValue_4.tag === 4) ? singleton_1(mainContent) : singleton_1(anonymousContent))))))) : singleton_1(mainContent)), delay(() => singleton_1(footer)));
    })))), ["application", react.createElement(react.Fragment, {}, ...elements)])])));
}

